import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";

function OkIcon() {
  return <DoneIcon sx={{ mr: 1 }} color="success" />;
}
function NotOkIcon() {
  return <CloseIcon sx={{ mr: 1 }} color="error" />;
}
function WarningIcon() {
  return <WarningAmberIcon sx={{ mr: 1 }} color="warning" />;
}

const flexStyle = { display: "flex", alignItems: "center" };

export default function SignatureInfo({ result }) {
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const isCertify = result.permission.certification;
  const somethingWrong = result.verifyCertsChainErrors.length !== 0 || result.revocationsOK.length === 0 || result.integrity.integrity === false;
  const cert = result.certDetails[0];
  function toggle() {
    setOpen((open) => !open);
  }

  function closeDialog() {
    setOpenDialog(false);
  }

  return (
    <div>
      <Box sx={{ ...flexStyle, color: somethingWrong ? "red" : "" }}>
        {open ? (
          <IconButton size="small" onClick={() => toggle()}>
            <ExpandMoreIcon />
          </IconButton>
        ) : (
          <IconButton size="small" onClick={() => toggle()}>
            <ChevronRightIcon />
          </IconButton>
        )}
        {isCertify ? <WorkspacePremiumIcon fontSize="small" sx={{ mx: "8px" }} /> : <BorderColorOutlinedIcon fontSize="small" sx={{ mx: "8px" }} />}
        <Typography sx={{ fontWeight: "bold" }}>
          {result.integrity.revision !== 1 && "Rev " + result.integrity.revision + ": "}
          {isCertify ? "Đã tạo và ký bởi " : "Ký bởi "}
          {result.cert.cn}
        </Typography>
      </Box>

      <Collapse in={open}>
        <Box pl={2}>
          <Box>
            {result.integrity.integrity === true ? (
              <Typography sx={flexStyle}>
                <OkIcon />
                Tài liệu nguyên vẹn, không bị chỉnh sửa
              </Typography>
            ) : (
              <Typography sx={flexStyle}>
                <NotOkIcon />
                Tài liệu đã bị chỉnh sửa sau khi ký
              </Typography>
            )}
          </Box>

          <Box>
            {result.revocationsOK.length === 0 ? (
              <Typography sx={flexStyle}>
                <NotOkIcon />
                Không thể xác thực định danh của người ký
              </Typography>
            ) : (
              <Typography sx={flexStyle}>
                <OkIcon />
                Định danh của người ký hợp lệ
              </Typography>
            )}
          </Box>

          <Typography sx={flexStyle}>
            {result.time.useTSA === true ? <OkIcon /> : <WarningIcon />}
            Ký lúc: {result.time.signTime}
          </Typography>

          <Typography color="primary" sx={{ "&:hover": { textDecoration: "underline" }, cursor: "pointer", ml: "30px" }} onClick={() => setOpenDialog(true)}>
            Chi tiết
          </Typography>

          <Dialog open={openDialog} onClose={closeDialog} fullWidth maxWidth="sm">
            <DialogTitle>Thông tin chi tiết</DialogTitle>
            <DialogContent>
              <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                <Tabs value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)}>
                  <Tab label="Chứng chỉ"></Tab>
                  <Tab label="Chữ ký"></Tab>
                </Tabs>
              </Box>

              <Box hidden={tabIndex !== 0} sx={{ "& > *": { mb: 1 } }}>
                <Box>
                  <Typography display={"inline"}>Cấp bởi: </Typography>
                  <Typography display={"inline"} sx={{ fontWeight: "bold" }}>
                    {formatIssuerDN(cert.issuerDN)}
                  </Typography>
                </Box>
                <Box>
                  <Typography display={"inline"}>Cấp cho: </Typography>
                  <Typography display={"inline"} sx={{ fontWeight: "bold" }}>
                    {formatSubjectDN(cert.subjectDN)}
                  </Typography>
                </Box>
                <Box>
                  <Typography display={"inline"}>Hợp lệ từ: </Typography>
                  <Typography display={"inline"} sx={{ fontWeight: "bold" }}>
                    {cert.notBefore}
                  </Typography>
                  <span>{" đến "}</span>
                  <Typography display={"inline"} sx={{ fontWeight: "bold" }}>
                    {cert.notAfter}
                  </Typography>
                </Box>
                {/* <Box>
                  <Typography display={"inline"}>Không sau: </Typography>
                  <Typography display={"inline"} sx={{ fontWeight: "bold" }}>
                    {cert.notAfter}
                  </Typography>
                </Box> */}
                <Box>
                  <Typography display={"inline"}>Trạng thái chứng chỉ khi ký: </Typography>
                  {cert.certStatusSigningTime === "VALID" && (
                    <Typography display={"inline"} sx={{ fontWeight: "bold", color: "green" }}>
                      {"Hợp lệ"}
                    </Typography>
                  )}
                  {cert.certStatusSigningTime === "EXPIRED" && (
                    <Typography display={"inline"} sx={{ fontWeight: "bold", color: "red" }}>
                      {"Đã hết hạn"}
                    </Typography>
                  )}
                  {cert.certStatusSigningTime === "NOT_VALID_YET" && (
                    <Typography display={"inline"} sx={{ fontWeight: "bold", color: "red" }}>
                      {"Chưa có hiêu lực"}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Typography display={"inline"}>Trạng thái chứng chỉ hiện tại: </Typography>
                  {cert.certStatusNow === "VALID" && (
                    <Typography display={"inline"} sx={{ fontWeight: "bold", color: "green" }}>
                      {"Hợp lệ"}
                    </Typography>
                  )}
                  {cert.certStatusNow === "EXPIRED" && (
                    <Typography display={"inline"} sx={{ fontWeight: "bold", color: "red" }}>
                      {"Đã hết hạn"}
                    </Typography>
                  )}
                  {cert.certStatusNow === "NOT_VALID_YET" && (
                    <Typography display={"inline"} sx={{ fontWeight: "bold", color: "red" }}>
                      {"Chưa có hiêu lực"}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Typography display={"inline"}>Trạng thái thu hồi: </Typography>
                  {result.revocationsOK.length ? (
                    <Typography display={"inline"} sx={{ fontWeight: "bold", color: "green" }}>
                      Chứng chỉ chưa bị thu hồi
                    </Typography>
                  ) : (
                    <Typography display={"inline"} sx={{ fontWeight: "bold", color: "red" }}>
                      Có vấn đề đã xảy ra trong khi kiểm tra trạng thái thu hồi!
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box hidden={tabIndex !== 1} sx={{ "& > *": { mb: 1 } }}>
                <Box>
                  <Typography display={"inline"}>Thuật toán băm: </Typography>
                  <Typography display={"inline"} sx={{ fontWeight: "bold" }}>
                    {result.crypto.hashAlgo}
                  </Typography>
                </Box>
                <Box>
                  <Typography display={"inline"}>Thuật toán mã hóa: </Typography>
                  <Typography display={"inline"} sx={{ fontWeight: "bold" }}>
                    {result.crypto.encryptAlgo}
                  </Typography>
                </Box>
                <Box>
                  <Typography display={"inline"}>Tên chuẩn chữ ký: </Typography>
                  <Typography display={"inline"} sx={{ fontWeight: "bold" }}>
                    {result.crypto.filterSubtype}
                  </Typography>
                </Box>
                <Box>
                  <Typography display={"inline"}>Tên thuộc tính ký: </Typography>
                  <Typography display={"inline"} sx={{ fontWeight: "bold" }}>
                    {result.metadata.fieldName} {!result.position.visible ? " (Invisible Signature)" : ""}
                  </Typography>
                </Box>
                <Box>
                  <Typography display={"inline"}>Ký vào lúc: </Typography>
                  <Typography display={"inline"} sx={{ fontWeight: "bold" }}>
                    {result.time.signTime} {`${result.time.useTSA ? " (From Secure Timestamp Authority)" : " (From the clock on signer's computer)"}`}
                  </Typography>
                </Box>

                {/* <Box>
                  <Typography display={"inline"}>Nơi ký: </Typography>
                  <Typography display={"inline"} sx={{ fontWeight: "bold" }}>
                    {result.metadata.location}
                  </Typography>
                </Box>

                <Box>
                  <Typography display={"inline"}>Lý do: </Typography>
                  <Typography display={"inline"} sx={{ fontWeight: "bold" }}>
                    {result.metadata.reason}
                  </Typography>
                </Box> */}
                {/* 
                <Box>
                  <Typography display={"inline"}>Permission </Typography>
                  <Typography display={"inline"} sx={{ fontWeight: "bold" }}>
                    {result.permission}
                  </Typography>
                </Box> */}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="primary" onClick={closeDialog}>
                Đóng
              </Button>
            </DialogActions>
          </Dialog>

          {/* {result.time.useTSA === true ? "(from Secure Time Stamp Authority)" : "(from the clock on the signer's computer)"} */}

          {/* <Box>{`Field: ${result.metadata.fieldName} ${result.position.visible === false && "(Invisible signature)"}`} </Box> */}
        </Box>
      </Collapse>
    </div>
  );
}

function formatIssuerDN(dn) {
  const parts = dn.split(",");
  const l = parts.length;
  return parts[l - 2] + "," + parts[l - 1];
}
function formatSubjectDN(dn) {
  const parts = dn.split(",");
  const l = parts.length;
  return parts[l - 3] + "," + parts[l - 2] + "," + parts[l - 1];
}
