import { useSnackbar } from "notistack";
import { useState } from "react";

export default function usePageIndex({ previousPage, nextPage, goToPage, pages }) {
  const { enqueueSnackbar } = useSnackbar();

  const [pageIndexTFValue, setPageIndexTFValue] = useState(1);

  function hdGoToPage() {
    const _pageIndex = pageIndexTFValue - 1;
    if (_pageIndex > pages.length - 1 || _pageIndex < 0)
      return enqueueSnackbar("Số trang không hợp lệ!", { variant: "warning", anchorOrigin: { vertical: "top", horizontal: "center" } });
    goToPage(_pageIndex);
  }

  function hdPrevPage() {
    previousPage();
    setPageIndexTFValue(Number(pageIndexTFValue) - 1);
  }

  function hdNextPage() {
    nextPage();
    setPageIndexTFValue(Number(pageIndexTFValue) + 1);
  }
  return { pageIndexTFValue, setPageIndexTFValue, hdPrevPage, hdNextPage, hdGoToPage };
}
