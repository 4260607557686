import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CreateIcon from "@mui/icons-material/Create";
import Crop54Icon from "@mui/icons-material/Crop54";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import { Box, Button as MuiButton, ButtonGroup } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSnackbar } from "notistack";
import React from "react";
import { useAppDispatch } from "../../redux/hooks";
import { hdInsertMajorSignature, hdInsertMinorSignature, hdInsertSignPlaceholder, hdInsertTextInput } from "./handler";
import { menuPaperSx } from "./style";

export default function InsertMenu({ profile, pageIndex, placeholder, hdSignDoc }) {
  const { enqueueSnackbar } = useSnackbar();
  const dp = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <ButtonGroup>
        <MuiButton variant="outlined" onClick={handleClick} startIcon={<AddCircleOutlineIcon />}>
          1. Chèn
        </MuiButton>
        <MuiButton variant="outlined" onClick={hdSignDoc} startIcon={<DriveFileRenameOutlineIcon />}>
          2. Ký
        </MuiButton>
      </ButtonGroup>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: menuPaperSx,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        {/* <MenuItem onClick={() => hdInsertMajorSignature(profile, pageIndex, dp)}>
          <ListItemIcon>
            <BorderColorIcon fontSize="small" />
          </ListItemIcon>
          Chữ ký
        </MenuItem> */}

        {/* <MenuItem onClick={() => hdInsertMinorSignature(profile, pageIndex, dp)}>
          <ListItemIcon>
            <CreateIcon fontSize="small" />
          </ListItemIcon>
          Chữ ký nháy
        </MenuItem> */}

        {/* <MenuItem onClick={() => hdInsertTextInput(pageIndex, dp)}>
          <ListItemIcon>
            <TextFieldsIcon fontSize="small" />
          </ListItemIcon>
          Văn bản
        </MenuItem> */}

        <MenuItem
          onClick={() => {
            if (placeholder) {
              return enqueueSnackbar("Chèn tối đa 1 placeholder!", {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "center" },
              });
            }
            hdInsertSignPlaceholder(profile.email, pageIndex, dp);
          }}>
          <ListItemIcon>
            <Crop54Icon fontSize="small" />
          </ListItemIcon>
          Chữ ký số hiện
        </MenuItem>
      </Menu>
    </Box>
  );
}
