import { Box, Tabs, Tab, Container, Paper, AppBar, Backdrop, Typography, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Navbar from "../../components/Navbar";

import SwipeableViews from "react-swipeable-views";
import Midterm from "./Midterm";
import EndTerm from "./EndTerm";

export default function GradeList() {
  let [searchParams, setSearchParams] = useSearchParams();
  const spTabIndex = searchParams.get("tabIndex");
  const [tabIndex, setTabIndex] = useState(Number(spTabIndex) || 0);

  const [signing, setSigning] = useState(false);

  return (
    <>
      <Navbar />
      <Box style={{ backgroundColor: "#f1f1f1", flexGrow: "1" }}>
        {signing && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={signing === true}>
            <Typography variant="h5">Đang ký...</Typography>
            <CircularProgress color="inherit" sx={{ ml: 1 }} />
          </Backdrop>
        )}

        <Container fixed sx={{ py: 2 }}>
          <Paper>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <AppBar position="static">
                <Tabs
                  indicatorColor="secondary"
                  textColor="inherit"
                  centered
                  // variant="fullWidth"
                  // variant="scrollable"
                  value={tabIndex}
                  onChange={(e, newValue) => {
                    setTabIndex(newValue);
                    setSearchParams({ tabIndex: newValue });
                  }}>
                  <Tab label="Giữa kỳ" style={{ fontSize: "1rem", fontWeight: "bolder" }} />
                  <Tab label="Cuối kỳ" style={{ fontSize: "1rem", fontWeight: "bolder" }} />
                </Tabs>
              </AppBar>
            </Box>
            <Box my={2}>
              <SwipeableViews
                axis={"x"}
                index={tabIndex}
                onChangeIndex={(index) => {
                  setTabIndex(index);
                  setSearchParams({ tabIndex: index });
                }}>
                <div hidden={tabIndex !== 0}>
                  <Midterm {...{ signing, setSigning }} />
                </div>
                <div hidden={tabIndex !== 1}>
                  <EndTerm {...{ signing, setSigning }} />
                </div>
              </SwipeableViews>
            </Box>
          </Paper>
        </Container>
      </Box>
    </>
  );
}
