import AskMetadata from "./AskMetadata";

export default function AskSignatureMetadata({ resolve, reject }) {
  function hdCancel() {
    reject({ cancel: true });
  }

  function hdSubmit({ reason, location, OTP }) {
    resolve({ reason, location, OTP });
  }

  return (
    <div>
      <AskMetadata open={true} hdCancel={hdCancel} hdSubmit={hdSubmit} />
    </div>
  );
}
