import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { ERR_TOP_CENTER } from "../../utility/snackbar-utils";
import { FILTER } from "./constance";
import { hdDownloadDoc, hdHide, hdOpenPdfInNewTab, hdUnhide } from "./handler";
import { useFilter, usePagination } from "./hooks";
import { shortenFileName } from "./utils";

export default function MySingleSignedDocs() {
  const [allDocs, setAllDocs] = useState(null);
  const { docs, filterBtnGroup } = useFilter({ allDocs });
  const { page, rowsPerPage, emptyRows, paginationComponent } = usePagination({ docs });

  const { enqueueSnackbar: eq } = useSnackbar();

  useEffect(() => {
    fetchDocs();
  }, []);

  async function fetchDocs() {
    try {
      const response = await axios.get("/sign/pdf/single-sign/my-docs");
      const _docs = response.data.reverse();
      setTimeout(() => {
        setAllDocs(_docs);
      }, 500);
    } catch (error) {
      error.response && eq(JSON.stringify(error.response.data), ERR_TOP_CENTER);
    }
  }

  return (
    <div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Tác giả</TableCell>
              <TableCell>Tài liệu gốc</TableCell>
              <TableCell>Thời gian tạo</TableCell>
              <TableCell align="center">Tài liệu đã ký</TableCell>
              <TableCell align="center">Ẩn/Hiện</TableCell>
            </TableRow>
          </TableHead>

          {allDocs !== null && allDocs.length > 0 && (
            <>
              <TableBody>
                {(rowsPerPage > 0 ? docs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : docs).map((doc, index) => (
                  <TableRow key={index}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{doc.author}</TableCell>
                    <TableCell>
                      <a href="/" style={{ display: "inline-flex", alignItems: "center" }} onClick={(e) => hdOpenPdfInNewTab(e, doc, eq, "/sign/pdf/single-sign/origin-pdf-file")}>
                        {shortenFileName(doc.originalFileName)} <OpenInNewIcon size="small" sx={{ ml: 1 }} />
                      </a>
                    </TableCell>
                    <TableCell>{new Date(doc.timestamp).toLocaleString()}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => {
                          const downloadFileName = "Signed_" + doc.originalFileName;
                          const path = "/sign/pdf/single-sign/signed-pdf-file";
                          hdDownloadDoc(doc, path, downloadFileName, eq);
                        }}>
                        <FileDownloadOutlinedIcon />
                      </IconButton>
                    </TableCell>

                    <TableCell align="center">
                      {!doc.status || doc.status === FILTER.UNHIDE ? (
                        <Tooltip placement="right" title="Ẩn đi">
                          <IconButton onClick={() => hdHide({ _id: doc._id, allDocs, setAllDocs, eq, path: "/sign/pdf/single-sign/update-status" })}>
                            <VisibilityOffIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip placement="right" title="Bỏ ẩn">
                          <IconButton onClick={() => hdUnhide({ _id: doc._id, allDocs, setAllDocs, eq, path: "/sign/pdf/single-sign/update-status" })}>
                            <VisibilityIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 50 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {/* <TableFooter>
                <TableRow>
                  <TableCell colSpan={3}>{filterBtnGroup}</TableCell>

                  {paginationComponent}
                </TableRow>
              </TableFooter> */}
            </>
          )}
        </Table>
      </TableContainer>

      {allDocs !== null && allDocs.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "center", paddingX: 2 }}>
          <Box sx={{ py: 1 }}>{filterBtnGroup}</Box>
          <Box>{paginationComponent}</Box>
        </Box>
      )}

      {allDocs !== null && allDocs.length === 0 && (
        <Box p={2}>
          <Typography variant="h6">Danh sách rỗng</Typography>
        </Box>
      )}

      {allDocs === null && <CircularProgress sx={{ m: 2 }} size="1.5rem" />}
    </div>
  );
}
