import DangerousIcon from "@mui/icons-material/Dangerous";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { Rnd } from "react-rnd";
import { removePlaceholder, updatePlaceholder } from "../../redux/slices/attachment";

export default function PlaceholderSingleSign({ placeholder }) {
  const dp = useDispatch();

  function hdStopDrag(e, data) {
    dp(updatePlaceholder({ id: placeholder.id, x: data.x, y: data.y }));
  }

  function hdStopResize(e, direction, ref, delta, position) {
    const w = ref.style.width;
    const h = ref.style.height;
    const width = typeof w === "number" ? w : Number(w.split("px")[0]);
    const height = typeof h === "number" ? h : Number(h.split("px")[0]);
    dp(updatePlaceholder({ id: placeholder.id, width, height, x: position.x, y: position.y }));
  }

  function hdRemovePlaceholder(id) {
    dp(removePlaceholder({ id }));
  }

  return (
    <Rnd
      // lockAspectRatio={true}
      bounds="parent"
      style={{ backgroundColor: "rgba(199, 227, 248, 0.85)" }}
      size={{ width: placeholder.width, height: placeholder.height }}
      position={{ x: placeholder.x, y: placeholder.y }}
      onDragStop={(e, data) => hdStopDrag(e, data)}
      onResizeStop={(e, direction, ref, delta, position) => hdStopResize(e, direction, ref, delta, position)}>
      <div style={{ position: "absolute", top: 2, right: 2 }}>
        <IconButton size="small" onClick={() => hdRemovePlaceholder(placeholder.id)}>
          <DangerousIcon color="error" />
        </IconButton>
      </div>
    </Rnd>
  );
}
