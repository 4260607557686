import AddIcon from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button as MuiButton,
  ButtonGroup,
  CircularProgress,
  Container,
  Divider,
  Grid as MuiGrid,
  IconButton,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import _ from "lodash";
import { useSnackbar } from "notistack";
import * as PDFLib from "pdf-lib";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Segment } from "semantic-ui-react";
import { useTheme } from "@mui/material/styles";
import AskSignatureMetadata from "../../components/AskSignatureMetadata";
import DragnDropZone from "../../components/DragnDropZone";
import Navbar from "../../components/Navbar";
import { Page } from "../../components/Page";
import PageIndex from "../../components/PageIndex";
import usePageIndex from "../../components/PageIndex/usePageIndex";
import PlaceholderMultiSignV2 from "../../components/PlaceholderDisplayer/PlaceholderMultiSign_v2";
import { usePdf } from "../../hooks/usePdf";
import { handlers, UploadTypes } from "../../hooks/useUploader";
import { useAppSelector } from "../../redux/hooks";
import { INFO_TOP_CENTER, SUCCESS_TOP_CENTER, WARN_TOP_CENTER } from "../../utility/snackbar-utils";
import { readAsArrayBuffer } from "../../utils/asyncReader";
import { genSignaturePlaceholder } from "../Sign/utils";
import Tour from "reactour";
import { tourConfig } from "./user-guild";
import styles from "./styles.module.css";

export default function MultiSign() {
  const profile = useAppSelector((state) => state.profile);
  const [inputFile, setInputFile] = useState(null);
  const [teacherIds, setTeacherIds] = useState([]);
  const [signers, setSigners] = useState([]);
  const remainOptions = _.difference(teacherIds, [...signers, profile.email]).filter((_) => _);
  const [dialog, setDialog] = useState(null);
  const [signing, setSigning] = useState(null);
  const [placeholders, setPlaceholders] = useState([]);

  const [isTourOpen, setIsTourOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const nvg = useNavigate();

  const { file, initialize, pageIndex, pages, isMultiPage, isFirstPage, isLastPage, currentPage, isSaving, previousPage, nextPage, goToPage, width } = usePdf();

  const { pageIndexTFValue, setPageIndexTFValue, hdPrevPage, hdNextPage, hdGoToPage } = usePageIndex({ previousPage, nextPage, goToPage, pages });

  const initializePageAndAttachments = (pdfDetails) => {
    initialize(pdfDetails);
  };

  async function hdUploadPdf(files) {
    const file = files[0];
    const result = await handlers[UploadTypes.PDF](file);
    initializePageAndAttachments(result);
    setInputFile(file);
    return file;
  }

  async function requireMetadataAndOTP() {
    return new Promise((resolve, reject) => {
      setDialog(<AskSignatureMetadata {...{ resolve, reject }} />);
    });
  }

  function hdAddSigner() {
    if (remainOptions.length === 0) return enqueueSnackbar("Đã hết người cùng ký để thêm!", INFO_TOP_CENTER);
    setSigners((prevSigners) => [...prevSigners, ""]);
  }

  function hdRemoveSigner(index) {
    const clone = [...signers];
    clone.splice(index, 1);
    setSigners(clone);
  }

  function hdChangeSigner(index, newValue) {
    const clone = [...signers];
    clone[index] = newValue;
    setSigners(clone);
  }

  function hdAddPlaceholder(email) {
    const newPlaceholder = genSignaturePlaceholder(email, pageIndex);
    setPlaceholders([...placeholders, newPlaceholder]);
  }
  function hdUpdatePlaceholder(patch) {
    const clone = [...placeholders];
    const plc = clone.find((_) => _.id === patch.id);
    Object.assign(plc, patch);
    setPlaceholders(clone);
  }
  function hdRemovePlaceholder(id) {
    setPlaceholders(placeholders.filter((_) => _.id !== id));
  }

  async function hdCertifyDoc() {
    if (file && profile && profile.email) {
      try {
        if (signers.some((item) => item === "")) {
          return enqueueSnackbar("Hãy loại bỏ những người ký rỗng trước khi tạo đồng ký!", WARN_TOP_CENTER);
        }

        if (signers.length + 1 !== placeholders.length) {
          // enqueueSnackbar("Hãy xác định vị trí chữ ký của những người ký!", WARN_TOP_CENTER);
          // setTimeout(() => {
          // setIsTourOpen(true);
          // }, 1000);
          setIsTourOpen(true);

          return;
        }

        const { reason, location, OTP } = await requireMetadataAndOTP();
        setDialog(null);
        setSigning(true);
        const formData = new FormData();
        formData.append("totp", OTP);
        formData.append("metadata", JSON.stringify({ reason, location }));
        formData.append("other-signers", JSON.stringify(signers));

        const pdfDoc = await PDFLib.PDFDocument.load(await readAsArrayBuffer(file));
        const enhancedPlaceholders = placeholders.map((plc) => {
          const pageHeight = pdfDoc.getPage(plc.pageIndex).getHeight();
          return { ...plc, pageHeight, fieldName: plc.email.replaceAll(".", "-") + "-" + Date.now() };
        });
        formData.append("placeholders", JSON.stringify(enhancedPlaceholders));
        formData.append("pdf-file", inputFile);
        const response = await axios.post("/sign/pdf/multi-sign/certify", formData);
        enqueueSnackbar("Bạn đã ký thành công! Đang gửi thông báo cho những người cùng ký khác!", SUCCESS_TOP_CENTER);
        setTimeout(() => {
          nvg("/documents?tabIndex=1");
        }, 500);
      } catch (error) {
        console.error(error);
        setDialog(null);
        if (error.cancel) return;
        console.log(error.response.data);
        const msg = (error?.response?.data ? error.response.data : error.toString()) || "";
        enqueueSnackbar("Có lỗi xảy ra: " + msg, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "center" } });
      } finally {
        setSigning(false);
      }
    } else {
      alert("Hãy tải file trước!");
    }
  }

  async function getTeacherIds() {
    const response = await axios.get("/sign/teacher-ids");
    setTeacherIds(response.data);
  }

  useEffect(() => {
    getTeacherIds();
  }, []);

  useEffect(() => {
    if (signers.length + 1 === placeholders.length) {
      setIsTourOpen(false);
    }
  }, [placeholders.length]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      <Navbar />
      {dialog}

      {signing && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={signing === true}>
          <Typography variant="h5">Đang ký...</Typography>
          <CircularProgress color="inherit" sx={{ ml: 1 }} />
        </Backdrop>
      )}

      <Tour
        onRequestClose={() => setIsTourOpen(false)}
        steps={tourConfig}
        isOpen={isTourOpen}
        maskClassName="mask"
        className={styles.helper}
        rounded={5}
        accentColor={"#5cb7b7"}
        disableDotsNavigation
        // onAfterOpen={this.disableBody}
        // onBeforeClose={this.enableBody}
      />

      <Box style={{ backgroundColor: "#f1f1f1", flexGrow: "1" }}>
        <Container fixed sx={{ py: 2 }}>
          <MuiGrid container spacing={2}>
            <MuiGrid item xs={12} lg={9}>
              {!file && <DragnDropZone onDropAccepted={hdUploadPdf} title="Kéo thả hoặc chọn file PDF để tạo đồng ký" />}

              {file && (
                <Box>
                  <Grid>
                    <Grid.Row style={{ paddingBottom: 0 }} centered>
                      <Grid.Column width={matches ? 16 : 12}>
                        <Box display={"flex"} justifyContent={matches ? "center" : "space-between"} alignItems={"center"} flexWrap="wrap" px={1}>
                          <Box pt={1} pb={matches ? 2 : 1}>
                            <PageIndex {...{ pageIndexTFValue, setPageIndexTFValue, hdGoToPage, pages, hdPrevPage, hdNextPage, matches }} />
                          </Box>

                          <ButtonGroup>
                            <MuiButton variant="outlined" onClick={hdCertifyDoc} disabled={isSaving} startIcon={<SaveAsIcon />}>
                              {isSaving ? "Đang ký" : "Tạo đồng ký"}
                            </MuiButton>
                          </ButtonGroup>
                        </Box>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Grid>
                    <Grid.Row>
                      {/* prev button */}
                      {!matches && (
                        <Grid.Column width={2} verticalAlign="middle" textAlign="center">
                          {isMultiPage && <Button circular icon="angle left" onClick={hdPrevPage} disabled={isFirstPage} />}
                        </Grid.Column>
                      )}
                      {/* pdf page */}
                      <Grid.Column width={matches ? 16 : 12} textAlign="center">
                        {currentPage && (
                          <Segment stacked={isMultiPage && !isLastPage} style={{ margin: "auto" }}>
                            <div style={{ position: "relative", overflow: "auto" }}>
                              <Page width={width} page={currentPage} />
                              {placeholders
                                .filter((plc) => plc.pageIndex === pageIndex)
                                .map((plc) => (
                                  <PlaceholderMultiSignV2 key={plc.id} {...{ placeholder: plc, hdUpdatePlaceholder, hdRemovePlaceholder }} />
                                ))}
                            </div>
                          </Segment>
                        )}
                      </Grid.Column>

                      {/* next button */}
                      {!matches && (
                        <Grid.Column width={2} verticalAlign="middle" textAlign="center">
                          {isMultiPage && <Button circular icon="angle right" onClick={hdNextPage} disabled={isLastPage} />}
                        </Grid.Column>
                      )}
                    </Grid.Row>
                  </Grid>
                </Box>
              )}
            </MuiGrid>

            <MuiGrid item xs={12} lg={3}>
              {file && (
                <Paper sx={{ px: 0.75, pt: 1, pb: 1 }}>
                  <Box sx={{ px: 1 }}>
                    <Typography variant="h6">Danh sách người ký</Typography>
                    <Divider sx={{ mb: 2 }} />
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton size="small" sx={{ mr: 0.5 }} onClick={() => hdAddPlaceholder(profile.email)} disabled={placeholders.find((plc) => plc.email === profile.email)}>
                      {placeholders.find((plc) => plc.email === profile.email) ? <DoneIcon color="success" /> : <LocationOnIcon className="addSigPlaceholderBtn" />}
                    </IconButton>

                    <TextField fullWidth size="small" value={profile.email} disabled></TextField>
                    <IconButton size="small" disabled sx={{ ml: 0.5 }}>
                      <Close />
                    </IconButton>
                  </Box>

                  {signers.map((signer, index) => (
                    <Box my={1} sx={{ display: "flex" }}>
                      <IconButton size="small" sx={{ mr: 0.5 }} onClick={() => hdAddPlaceholder(signer)} disabled={!signer || placeholders.find((plc) => plc.email === signer)}>
                        {placeholders.find((plc) => plc.email === signer) ? <DoneIcon color="success" /> : <LocationOnIcon className="addSigPlaceholderBtn" />}
                      </IconButton>

                      <Autocomplete
                        disableClearable
                        fullWidth
                        value={signer}
                        onChange={(e, newValue) => hdChangeSigner(index, newValue)}
                        options={remainOptions}
                        renderInput={(params) => <TextField {...params} size="small" />}
                      />

                      <IconButton size="small" sx={{ ml: 0.5 }} onClick={() => hdRemoveSigner(index)}>
                        <Close />
                      </IconButton>
                    </Box>
                  ))}

                  <Box mt={1} pr={0.5} textAlign="right">
                    <MuiButton variant="outlined" endIcon={<AddIcon />} onClick={() => hdAddSigner()}>
                      Thêm
                    </MuiButton>
                  </Box>
                </Paper>
              )}
            </MuiGrid>
          </MuiGrid>
        </Container>
      </Box>
    </>
  );
}
