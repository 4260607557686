import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { prepareAssets } from "./utils/prepareAssets";
import { getToken } from "./utility/mng-token";
import axios from "axios";
import { SnackbarProvider } from "notistack";
import { CookiesProvider } from "react-cookie";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

prepareAssets();

axios.defaults.baseURL = `${process.env.REACT_APP_WEB_BE_URL}`;
const token = getToken();
if (token) {
  axios.defaults.headers.common["Authorization"] = token;
}

const theme = createTheme({
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 600,
  //     md: 960,
  //     lg: 1280,
  //     xl: 1536,
  //   },
  // },
});

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
          <CookiesProvider>
            <ThemeProvider theme={theme}>
            <App />
            </ThemeProvider>
          </CookiesProvider>
        </SnackbarProvider>
      </Provider>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
