import DangerousIcon from "@mui/icons-material/Dangerous";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Rnd } from "react-rnd";
import { updateAttachment } from "../../redux/slices/attachment";
import { hdRemoveAttachment, hdStopDrag, hdStopResize, style as boxStyle } from "./shared";

export default function TextDisplayerV2({ attachment }) {
  const dp = useDispatch();

  function hdUpdateText(e) {
    dp(updateAttachment({ id: attachment.id, text: e.target.value }));
  }

  const [style, setStyle] = useState(attachment.style);
  function updateStyle(name, value) {
    setStyle((style) => ({ ...style, [name]: value }));
  }
  function hdUpdateTextStyle() {
    dp(updateAttachment({ id: attachment.id, style }));
    handleClose();
  }

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Rnd
      enableUserSelectHack={false}
      bounds="parent"
      style={boxStyle}
      size={{ width: attachment.width, height: attachment.height }}
      position={{ x: attachment.x, y: attachment.y }}
      onDragStop={(e, data) => hdStopDrag(e, data, dp, attachment.id)}
      onResizeStop={(e, direction, ref, delta, position) => hdStopResize(e, direction, ref, delta, position, attachment.id, dp)}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", px: 1, pt: 0.5 }}>
        <IconButton size="small" onClick={() => handleClickOpen()}>
          <SettingsIcon style={{ color: "black" }} />
        </IconButton>

        <IconButton size="small" onClick={() => hdRemoveAttachment(attachment.id, dp)}>
          <DangerousIcon color="error" />
        </IconButton>
      </Box>

      <Box px={1}>
        <TextField fullWidth variant="standard" inputProps={{ style }} value={attachment.text} onChange={(e) => hdUpdateText(e)} />
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Tùy chỉnh</DialogTitle>
        <DialogContent>
          <Box py={4}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography sx={{ flexShrink: 0, pr: 2 }}>Font size:</Typography>
              <Slider valueLabelDisplay="auto" value={style.fontSize} onChange={(e, value) => updateStyle("fontSize", value)} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Hủy</Button>
          <Button onClick={hdUpdateTextStyle} variant="outlined">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Rnd>
  );
}
