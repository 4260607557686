import { Box, Container } from "@mui/material";
import { NavLink } from "react-router-dom";
import "./style.css";

export default function Navbar() {
  return (
    <div style={{ boxShadow: "0 9px 6px -6px black", position: "relative" }}>
      <Container fixed>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            color: "black",
            "& a": { p: "20px", textDecoration: "none", color: "inherit", textTransform: "uppercase" },
            "& a:hover": { color: "white", backgroundColor: "#9b0504" },
          }}>
          <NavLink className={({ isActive }) => (isActive ? "active-navbar-link" : "")} to="/esign">
            Đơn ký
          </NavLink>
          <NavLink className={({ isActive }) => (isActive ? "active-navbar-link" : "")} to="/multi-signatures">
            Đồng ký
          </NavLink>
          {/* <NavLink className={({ isActive }) => (isActive ? "active-navbar-link" : "")} to="/grades">
            Quản lý bảng điểm
          </NavLink> */}

          <NavLink className={({ isActive }) => (isActive ? "active-navbar-link" : "")} to="/verify">
            Xác thực
          </NavLink>
          <NavLink className={({ isActive }) => (isActive ? "active-navbar-link" : "")} to="/documents">
            Danh sách tài liệu
          </NavLink>

          <NavLink className={({ isActive }) => (isActive ? "active-navbar-link" : "")} to="/instruction-doc">
            HDSD
          </NavLink>
        </Box>
      </Container>
    </div>
  );
}
