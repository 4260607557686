import { removeAttachment, updateAttachment } from "../../redux/slices/attachment";

export function hdStopDrag(e, data, dp, id) {
  let { x, y } = data;
  dp(updateAttachment({ id, x, y }));
}

export function hdStopResize(e, direction, ref, delta, position, id, dp) {
  const w = ref.style.width;
  const h = ref.style.height;
  const width = typeof w === "number" ? w : Number(w.split("px")[0]);
  const height = typeof h === "number" ? h : Number(h.split("px")[0]);
  dp(updateAttachment({ id, width, height, x: position.x, y: position.y }));
}

export function hdRemoveAttachment(id, dp) {
  dp(removeAttachment({ id }));
}

export const style = {
  border: "solid 2px #1976d2",
};
