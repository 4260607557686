import { Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Header, Icon, Button } from "semantic-ui-react";
import { ERR_TOP_CENTER } from "../../utility/snackbar-utils";
import styles from "./styles.module.css";

import UseAnimations from "react-useanimations";
// EVERY ANIMATION NEEDS TO BE IMPORTED FIRST -> YOUR BUNDLE WILL INCLUDE ONLY WHAT IT NEEDS
import arrowUp from "react-useanimations/lib/arrowUp";

const baseStyle = {
  height: "75vh",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "40px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  color: "rgba(0,0,0,0.7)",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const activeStyle = {
  borderColor: "#1976d2",
};

const acceptStyle = {
  borderColor: "#42a5f5",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function DragnDropZone({ title = "Tải file PDF để bắt đầu", onDropAccepted }) {
  const { enqueueSnackbar } = useSnackbar();

  function onDropRejected(fileRejections) {
    enqueueSnackbar("Chỉ chấp nhận file PDF!", ERR_TOP_CENTER);
  }

  const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: { "application/pdf": [".pdf"] },
    onDropRejected,
    onDropAccepted,
    maxFiles: 1,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragActive, isDragReject, isDragAccept]
  );

  return (
    <Paper>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <>
          <div style={{ paddingTop: 16, paddingBottom: 16 }}>
            <Button primary>Tải PDF</Button>
          </div>
          <Header className={styles.customHeading}>{title}</Header>
          <UseAnimations animation={arrowUp} size="96" />
        </>
      </div>
    </Paper>
  );
}
