import DoneIcon from "@mui/icons-material/Done";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FILTER } from "./constance";
import { hdClickSign, hdDownloadDoc, hdHide, hdOpenPdfInNewTab, hdUnhide } from "./handler";
import { useFilter, usePagination } from "./hooks";
import { shortenFileName } from "./utils";

export default function MySignRequests({ signing, setSigning }) {
  const [allDocs, setAllDocs] = useState(null);
  const { docs, filterBtnGroup } = useFilter({ allDocs });
  const { page, rowsPerPage, emptyRows, paginationComponent } = usePagination({ docs });

  const teacherId = useSelector((state) => state.profile.email);
  const [dialog, setDialog] = useState(null);
  // const [signing, setSigning] = useState(false);
  const { enqueueSnackbar: eq } = useSnackbar();

  async function fetchDocs() {
    const response = await axios.get("/sign/pdf/multi-sign/my-sign-requests");
    const _docs = response.data.reverse();
    setTimeout(() => {
      setAllDocs(_docs);
    }, 500);
  }

  useEffect(() => {
    if (signing === false) fetchDocs();
  }, [signing]);

  return (
    <div>
      {dialog}

      {/* {signing && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={signing === true}>
          <Typography variant="h5">Đang ký...</Typography>
          <CircularProgress color="inherit" sx={{ ml: 1 }} />
        </Backdrop>
      )} */}

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Tác giả</TableCell>
              <TableCell>Tài liệu gốc</TableCell>
              <TableCell>Thời gian tạo</TableCell>
              <TableCell>Người đồng ký</TableCell>
              <TableCell align="center">Ký</TableCell>
              <TableCell align="center">Tài liệu đã ký</TableCell>
              <TableCell align="center">Ẩn/Hiện</TableCell>
            </TableRow>
          </TableHead>

          {allDocs !== null && allDocs.length > 0 && (
            <>
              <TableBody>
                {(rowsPerPage > 0 ? docs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : docs).map((doc, index) => (
                  <TableRow key={index}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{doc.author.teacherId}</TableCell>
                    <TableCell>
                      <a href="/" style={{ display: "inline-flex", alignItems: "center" }} onClick={(e) => hdOpenPdfInNewTab(e, doc, eq, "/sign/pdf/multi-sign/origin-pdf-file")}>
                        {shortenFileName(doc.originalFileName)} <OpenInNewIcon size="small" sx={{ ml: 1 }} />
                      </a>
                    </TableCell>
                    <TableCell>{new Date(doc.author.timestamp).toLocaleString()}</TableCell>
                    <TableCell>
                      {doc.signers.map((signer) => {
                        const signature = doc.signatures.find((sig) => sig.teacherId === signer);
                        if (!signature) return <Box sx={{ mb: "4px" }}>{signer}</Box>;
                        const timeStr = new Date(signature.timestamp).toLocaleString();
                        return (
                          <Box sx={{ display: "flex", alignItems: "end", mb: "4px" }}>
                            {signer}{" "}
                            <Tooltip title={`Đã ký vào: ${timeStr}`} placement="top">
                              <DoneIcon size="small" color="success" sx={{ ml: 1 }} />
                            </Tooltip>
                          </Box>
                        );
                      })}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        disabled={Boolean(doc.signatures.map((sig) => sig.teacherId).includes(teacherId))}
                        onClick={() => hdClickSign(doc._id, setDialog, eq, setSigning, "/sign/pdf/multi-sign/approve")}>
                        <DriveFileRenameOutlineIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => {
                          const downloadFileName = doc.signatures.length + 1 + "-" + (doc.signers.length + 1) + "-Signed_" + doc.originalFileName;
                          const path = "/sign/pdf/multi-sign/current-pdf-file";
                          hdDownloadDoc(doc, path, downloadFileName, eq);
                        }}>
                        <FileDownloadOutlinedIcon />
                      </IconButton>
                    </TableCell>

                    <TableCell align="center">
                      {!doc.status || doc.status === FILTER.UNHIDE ? (
                        <Tooltip placement="right" title="Ẩn đi">
                          <IconButton onClick={() => hdHide({ _id: doc._id, allDocs, setAllDocs, eq, path: "/sign/pdf/multi-sign/update-status" })}>
                            <VisibilityOffIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip placement="right" title="Bỏ ẩn">
                          <IconButton onClick={() => hdUnhide({ _id: doc._id, allDocs, setAllDocs, eq, path: "/sign/pdf/multi-sign/update-status" })}>
                            <VisibilityIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 50 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>

              {/* <TableFooter>
                <TableRow>
                  <TableCell colSpan={3}>{filterBtnGroup}</TableCell>
                  {paginationComponent}
                </TableRow>
              </TableFooter> */}
            </>
          )}
        </Table>
      </TableContainer>

      {allDocs !== null && allDocs.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "center", paddingX: 2 }}>
          <Box sx={{ py: 1 }}>{filterBtnGroup}</Box>
          <Box>{paginationComponent}</Box>
        </Box>
      )}

      {allDocs !== null && allDocs.length === 0 && (
        <Box p={2}>
          <Typography variant="h6">Danh sách rỗng</Typography>
        </Box>
      )}

      {allDocs === null && <CircularProgress sx={{ m: 2 }} size="1.5rem" />}
    </div>
  );
}
