import { Box, CircularProgress, Container, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Button, Segment } from "semantic-ui-react";
import DragnDropZone from "../../components/DragnDropZone";
import Navbar from "../../components/Navbar";
import { Page } from "../../components/Page";
import PageIndex from "../../components/PageIndex";
import usePageIndex from "../../components/PageIndex/usePageIndex";
import { usePdf } from "../../hooks/usePdf";
import { handlers, UploadTypes } from "../../hooks/useUploader";
import { ERR_TOP_CENTER } from "../../utility/snackbar-utils";
import SignatureInfo from "./SignatureInfo";

export default function Verify() {
  const [inputFile, setInputFile] = useState(null);
  const [verifySignatureResults, setVerifyResults] = useState();
  const [fetching, setFetching] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const { file, initialize, pageIndex, pages, isMultiPage, isFirstPage, isLastPage, currentPage, previousPage, nextPage, goToPage, width } = usePdf();
  const { pageIndexTFValue, setPageIndexTFValue, hdPrevPage, hdNextPage, hdGoToPage } = usePageIndex({ previousPage, nextPage, goToPage, pages });
  const initializePageAndAttachments = (pdfDetails) => {
    initialize(pdfDetails);
  };

  async function hdUploadPdf(files) {
    const file = files[0];
    const result = await handlers[UploadTypes.PDF](file);
    initializePageAndAttachments(result);
    setInputFile(file);
    return file;
  }

  async function verify() {
    if (inputFile) {
      setFetching(true);
      try {
        const formData = new FormData();
        formData.append("pdf-file", inputFile);
        const response = await axios.post("/verify/pdf", formData);
        setVerifyResults(response.data);
        console.log("🚧 --> verify --> response.data", response.data);
      } catch (error) {
        error.response && enqueueSnackbar(JSON.stringify(error.response.data), ERR_TOP_CENTER);
      } finally {
        setFetching(false);
      }
    }
  }

  useEffect(() => {
    verify();
  }, [inputFile]);

  return (
    <>
      <Navbar />
      <Box style={{ background: "#f1f1f1", flexGrow: "1" }}>
        <Container fixed sx={{ py: 2 }}>
          {!file && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={9}>
                <DragnDropZone onDropAccepted={hdUploadPdf} title="Kéo thả hoặc chọn file PDF để xác thực" />
              </Grid>
            </Grid>
          )}

          {file && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Box>
                  <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mb={2}>
                    <PageIndex {...{ pageIndexTFValue, setPageIndexTFValue, hdGoToPage, pages, hdPrevPage, hdNextPage, matches: true }} />
                  </Box>

                  {currentPage && (
                    <Segment stacked={isMultiPage && !isLastPage} style={{ margin: "auto" }}>
                      <div style={{ position: "relative", overflow: "auto" }}>
                        <Page width={width} page={currentPage} />
                      </div>
                    </Segment>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper sx={{ py: 2, px: 1 }}>
                  {fetching === true && (
                    <Typography sx={{ display: "flex", alignItems: "center", pl: 2 }}>
                      Đang kiểm tra thông tin chữ ký ... <CircularProgress size={"1.5rem"} sx={{ ml: 1 }} color="primary" />
                    </Typography>
                  )}
                  {fetching === false && verifySignatureResults && (
                    <Box>
                      {verifySignatureResults.map((result, index) => (
                        <Box mb={1}>
                          <SignatureInfo result={result} />
                        </Box>
                      ))}
                    </Box>
                  )}
                  {fetching === false && verifySignatureResults && verifySignatureResults.length === 0 && (
                    <Box px={1}>
                      <Typography variant="h6">Không tìm thấy thông tin chữ ký nào!</Typography>
                    </Box>
                  )}
                </Paper>
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
    </>
  );
}
