import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  attachments: [],
  placeholder: null,
};

export const attachmentSlice = createSlice({
  name: "attachment",
  initialState,
  reducers: {
    addAttachment: (state, action) => {
      state.attachments.push(action.payload);
    },
    updateAttachment: (state, action) => {
      const attachment = state.attachments.find((att) => att.id === action.payload.id);
      Object.assign(attachment, action.payload);
    },
    removeAttachment: (state, action) => {
      state.attachments = state.attachments.filter((att) => att.id !== action.payload.id);
    },
    addPlaceholder: (state, action) => {
      state.placeholder = action.payload;
    },
    updatePlaceholder: (state, action) => {
      Object.assign(state.placeholder, action.payload);
    },
    removePlaceholder: (state, action) => {
      state.placeholder = null;
    },
    reset: (state, action) => {
      state = initialState;
      return state;
    },
  },
});

export const { addAttachment, updateAttachment, removeAttachment, addPlaceholder, updatePlaceholder, removePlaceholder, reset } = attachmentSlice.actions;
export default attachmentSlice.reducer;
