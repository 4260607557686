export function formatLocalString2Line(timestamp) {
  const timeStr = new Date(timestamp).toLocaleString();
  const parts = timeStr.split(",");
  return (
    <div>
      <div>{parts[0]}</div>
      <div>{parts[1]}</div>
    </div>
  );
}
