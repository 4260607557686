import DangerousIcon from "@mui/icons-material/Dangerous";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { Rnd } from "react-rnd";
import { hdRemoveAttachment, hdStopDrag, hdStopResize, style } from "./shared";

export default function ImageDisplayerV2({ attachment }) {
  const dp = useDispatch();

  return (
    <Rnd
      // lockAspectRatio={true}
      bounds="parent"
      style={style}
      size={{ width: attachment.width, height: attachment.height }}
      position={{ x: attachment.x, y: attachment.y }}
      onDragStop={(e, data) => hdStopDrag(e, data, dp, attachment.id)}
      onResizeStop={(e, direction, ref, delta, position) => hdStopResize(e, direction, ref, delta, position, attachment.id, dp)}>
      <div style={{ position: "absolute", top: 2, right: 2 }}>
        <IconButton size="small" onClick={() => hdRemoveAttachment(attachment.id, dp)}>
          <DangerousIcon color="error" />
        </IconButton>
      </div>

      <img draggable="false" alt="signature" src={attachment.url} style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "scale-down" }} />
    </Rnd>
  );
}
