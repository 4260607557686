import { Backdrop, Box, CircularProgress, Container, Grid as MuiGrid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import download from "downloadjs";
import { useSnackbar } from "notistack";
import * as PDFLib from "pdf-lib";
import React, { useEffect, useState } from "react";
import { Button, Grid, Segment } from "semantic-ui-react";
import DragnDropZone from "src/components/DragnDropZone";
import PageIndex from "src/components/PageIndex";
import usePageIndex from "src/components/PageIndex/usePageIndex";
import { hustAxios } from "src/config/axios-config";
import AskSignatureMetadata from "../../components/AskSignatureMetadata";
import AttachmentsDisplayer from "../../components/AttachmentDisplayer";
import Navbar from "../../components/Navbar";
import { Page } from "../../components/Page";
import PlaceholderSingleSign from "../../components/PlaceholderDisplayer/PlaceholderSingleSign";
import { Pdf, usePdf } from "../../hooks/usePdf";
import { handlers, UploadTypes } from "../../hooks/useUploader";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { reset } from "../../redux/slices/attachment";
import { readAsArrayBuffer } from "../../utils/asyncReader";
import { getPDFBytes } from "../../utils/pdf";
import { fetchPdfFromURL } from "./handler";
import InsertMenu from "./InsertMenu";
import Signature from "./Signature";
import { ERR_TOP_CENTER } from "src/utility/snackbar-utils";

export default function Sign() {
  const { file, initialize, pageIndex, pages, isMultiPage, isFirstPage, isLastPage, currentPage, previousPage, nextPage, goToPage, width } = usePdf();

  const { pageIndexTFValue, setPageIndexTFValue, hdPrevPage, hdNextPage, hdGoToPage } = usePageIndex({ previousPage, nextPage, goToPage, pages });

  const profile = useAppSelector((state) => state.profile);
  const attachments = useAppSelector((state) => state.attachment.attachments);
  const placeholder: any = useAppSelector((state) => state.attachment.placeholder);
  const pageAttachments = attachments.filter((att: any) => att.pageIndex === pageIndex);

  const [signing, setSigning] = useState<any>(null);

  const [dialog, setDialog] = useState<any>(null);

  const { enqueueSnackbar } = useSnackbar();
  const dp = useAppDispatch();

  function resetState() {
    dp(reset({}));
  }
  useEffect(() => {
    return resetState;
  }, []);

  const params = new URLSearchParams(window.location.search);
  let pdfURL = params.get("url");
  const [fetching, setFetching] = useState<any>(null);

  useEffect(() => {
    fetchPdfFromURL(pdfURL, setFetching, initialize, enqueueSnackbar);
  }, [pdfURL]);

  const initializePageAndAttachments = (pdfDetails: Pdf) => {
    initialize(pdfDetails);
  };

  async function hdUploadPdf(files) {
    const file = files[0];
    const result = await handlers[UploadTypes.PDF](file);
    initializePageAndAttachments(result as Pdf);
    return file;
  }

  async function requireMetadataAndOTP(): Promise<{ reason: string; location: string; OTP: string }> {
    return new Promise((resolve, reject) => {
      setDialog(<AskSignatureMetadata {...{ resolve, reject }} />);
    });
  }

  async function hdSignDoc() {
    if (file && profile && profile.email) {
      try {
        // const { pdfBytes: bytes } = await getPDFBytes(file, attachments);
        const { reason, location, OTP } = await requireMetadataAndOTP();
        setDialog(null);

        const formData = new FormData();
        formData.append("totp", OTP);
        formData.append("metadata", JSON.stringify({ reason, location }));
        // formData.append("pdf-file", new File([bytes], file.name, { type: "application/pdf" }));
        formData.append("pdf-file", file);

        setSigning(true);
        let response;
        if (placeholder) {
          const pdfDoc = await PDFLib.PDFDocument.load(await readAsArrayBuffer(file));
          const pageHeight = pdfDoc.getPage(placeholder.pageIndex).getHeight();
          const fieldName = `${profile.name || profile.email.split("@")[0].replaceAll(".", "-")} - ${Date.now()}`;
          // formData.append("placeholder", JSON.stringify({ ...placeholder, pageHeight, fieldName }));
          formData.append(
            "placeholder",
            JSON.stringify({ ...placeholder, pageHeight, fieldName, x: placeholder.x - 4, height: placeholder.height - 3, width: placeholder.width - 4 })
          );
          response = await axios.post("/sign/pdf/single-sign/visible", formData, { responseType: "blob" });
        } else {
          response = await axios.post("/sign/pdf/single-sign/invisible", formData, { responseType: "blob" });
        }

        if (pdfURL) {
          const parts = pdfURL.split("/");
          const pdfName = parts[parts.length - 1];
          const signedFile = new File([response.data], pdfName, { type: "application/pdf" });
          const formData = new FormData();
          formData.append("Filedata", signedFile);
          await hustAxios.post(`${process.env.REACT_APP_HUST_ENDPOINT}?urlFileExit=${pdfURL}`, formData);
        }

        enqueueSnackbar("Đã ký thành công!", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "center" } });

        setTimeout(() => {
          download(response.data, "Signed_" + file.name, "application/pdf");
        }, 1000);
      } catch (error: any) {
        console.error(error);
        setDialog(null);
        if (error.cancel) return;

        if (error?.response?.data) {
          let text = await error.response.data.text();
          if (text.toLowerCase().includes("pdf format error")) {
            text = "Lỗi: file PDF không hợp lệ! Nếu bạn sử dụng Macbook, khi export file pdf, vui lòng lựa chọn 'Best for electronic distribution' thay vì 'Best for printing'";
            return enqueueSnackbar(text, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "center" } });
          }
        }

        const errMsg = (error?.response?.data ? await error.response.data.text() : error.toString()) || "";
        enqueueSnackbar("Có lỗi xảy ra: " + errMsg, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "center" } });
      } finally {
        setSigning(false);
      }
    } else {
      alert("Bạn cần đăng nhập và tải file lên trước!");
    }
  }

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      <Navbar />
      {dialog}

      {signing && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={signing === true}>
          <Typography variant="h5">Đang ký...</Typography>
          <CircularProgress color="inherit" sx={{ ml: 1 }} />
        </Backdrop>
      )}

      <Box style={{ backgroundColor: "#f1f1f1", flexGrow: "1" }}>
        {pdfURL && fetching === true && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <Typography variant="h5">Đang tải file PDF...</Typography>
            <CircularProgress color="inherit" sx={{ ml: 1 }} />
          </Backdrop>
        )}

        <Container fixed sx={{ py: 2 }}>
          <MuiGrid container spacing={2}>
            <MuiGrid item xs={12} md={9}>
              {!file && !pdfURL && <DragnDropZone onDropAccepted={hdUploadPdf} title="Kéo thả hoặc chọn file PDF để thực hiện đơn ký" />}

              {!(!file && !pdfURL) && (
                <Box>
                  <Grid>
                    <Grid.Row style={{ paddingBottom: 0 }} centered>
                      <Grid.Column width={matches ? 16 : 12}>
                        <Box display={"flex"} justifyContent={matches ? "center" : "space-between"} alignItems={"center"} flexWrap="wrap" px={1}>
                          <Box pt={1} pb={matches ? 2 : 1}>
                            <PageIndex {...{ pageIndexTFValue, setPageIndexTFValue, hdGoToPage, pages, hdPrevPage, hdNextPage, matches }} />
                          </Box>

                          <InsertMenu {...{ profile, pageIndex, placeholder, hdSignDoc }} />
                        </Box>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Grid>
                    <Grid.Row>
                      {!matches && (
                        <Grid.Column width={2} verticalAlign="middle" textAlign="center">
                          {isMultiPage && <Button circular icon="angle left" onClick={hdPrevPage} disabled={isFirstPage} />}
                        </Grid.Column>
                      )}

                      <Grid.Column width={matches ? 16 : 12} textAlign="center">
                        {currentPage && (
                          <Segment stacked={isMultiPage && !isLastPage} style={{ margin: "auto" }}>
                            <div style={{ position: "relative", overflow: "auto", margin: "auto", width: width }}>
                              <Page width={width} page={currentPage} />
                              <AttachmentsDisplayer attachments={pageAttachments} />
                              {placeholder?.pageIndex === pageIndex && <PlaceholderSingleSign placeholder={placeholder} />}
                            </div>
                          </Segment>
                        )}
                      </Grid.Column>

                      {!matches && (
                        <Grid.Column width={2} verticalAlign="middle" textAlign="center">
                          {isMultiPage && <Button circular icon="angle right" onClick={hdNextPage} disabled={isLastPage} />}
                        </Grid.Column>
                      )}
                    </Grid.Row>
                  </Grid>
                </Box>
              )}
            </MuiGrid>

            <MuiGrid item xs={12} md={3} sx={{ pl: 1 }}>
              <Signature profile={profile} />
            </MuiGrid>
          </MuiGrid>
        </Container>
      </Box>
    </>
  );
}
