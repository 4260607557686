import DangerousIcon from "@mui/icons-material/Dangerous";
import { IconButton } from "@mui/material";
import { Rnd } from "react-rnd";

export default function PlaceholderMultiSignV2({ placeholder, hdUpdatePlaceholder, hdRemovePlaceholder }) {
  function hdStopDrag(e, data) {
    hdUpdatePlaceholder({ id: placeholder.id, x: data.x, y: data.y });
  }

  function hdStopResize(e, direction, ref, delta, position) {
    const w = ref.style.width;
    const h = ref.style.height;
    const width = typeof w === "number" ? w : Number(w.split("px")[0]);
    const height = typeof h === "number" ? h : Number(h.split("px")[0]);
    hdUpdatePlaceholder({ id: placeholder.id, width, height, x: position.x, y: position.y });
  }

  return (
    <Rnd
      bounds="parent"
      style={{ backgroundColor: "rgba(199, 227, 248, 0.85)", display: "flex", justifyContent: "center", alignItems: "center" }}
      size={{ width: placeholder.width, height: placeholder.height }}
      position={{ x: placeholder.x, y: placeholder.y }}
      onDragStop={(e, data) => hdStopDrag(e, data)}
      onResizeStop={(e, direction, ref, delta, position) => hdStopResize(e, direction, ref, delta, position)}>
      <div style={{ position: "absolute", top: 2, right: 2 }}>
        <IconButton size="small" onClick={() => hdRemovePlaceholder(placeholder.id)}>
          <DangerousIcon color="error" />
        </IconButton>
      </div>

      <div>{placeholder.email}</div>
    </Rnd>
  );
}
