import CreateIcon from "@mui/icons-material/Create";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { DrawingModal } from "../../modals/components/DrawingModal";

interface Props {
  title: string;
  hdUploadImage: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setEditing: any;
  editing: boolean;
  hdSaveSvg: any;
}

export default function CreateSignature({ title, hdUploadImage, setEditing, editing, hdSaveSvg }: Props) {
  const [openDrawing, setOpenDrawing] = useState(false);

  return (
    <Paper sx={{ padding: 2 }}>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"flex-start"}>
        <Typography variant="h6">{title}</Typography>

        {editing && (
          <Box>
            <Button variant="outlined" size="small" onClick={() => setEditing(false)}>
              Hủy bỏ
            </Button>
          </Box>
        )}
      </Box>

      <Divider style={{ marginTop: "4px" }} />

      <Box sx={{ border: "2px dashed black", display: "flex", justifyContent: "center", alignItems: "center", height: "150px", mt: 2 }}>
        <label htmlFor="major-signature">
          <input style={{ display: "none" }} accept="image/*" id="major-signature" type="file" onChange={(e) => hdUploadImage(e)} />
          <Button variant="outlined" startIcon={<UploadFileIcon />} component="span">
            Tải
          </Button>
        </label>

        <Button variant="outlined" startIcon={<CreateIcon />} sx={{ marginLeft: 1 }} component="span" onClick={() => setOpenDrawing(true)}>
          Vẽ
        </Button>
      </Box>

      <DrawingModal open={openDrawing} dismiss={() => setOpenDrawing(false)} confirm={hdSaveSvg}></DrawingModal>
    </Paper>
  );
}
