import axios from "axios";
import AskSignatureMetadata from "../../components/AskSignatureMetadata";
import { ERR_TOP_CENTER, SUCCESS_TOP_CENTER } from "../../utility/snackbar-utils";
import download from "downloadjs";
import { FILTER } from "./constance";

export function requireMetadataAndOTP(setDialog) {
  return new Promise((resolve, reject) => {
    setDialog(<AskSignatureMetadata {...{ resolve, reject }} />);
  });
}

export async function hdClickSign(_id, setDialog, enqueueSnackbar, setSigning, path) {
  try {
    const { reason, location, OTP } = await requireMetadataAndOTP(setDialog);
    setSigning(true);
    setDialog(null);
    const response = await axios.post(path, { totp: OTP, metadata: JSON.stringify({ reason, location }), _id });
    enqueueSnackbar("Đã ký thành công!", SUCCESS_TOP_CENTER);
  } catch (error) {
    if (error.cancel) return;
    const msg = error?.response?.data ? JSON.stringify(error.response.data) : error.toString() || "";
    enqueueSnackbar("Có lỗi xảy ra: " + msg, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "center" } });
  } finally {
    setDialog(null);
    setSigning(false);
  }
}

export async function hdOpenPdfInNewTab(e, doc, enqueueSnackbar, path) {
  try {
    e.preventDefault();
    const response = await axios.get(path, { responseType: "blob", params: { _id: doc._id } });
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const pdfWindow = window.open();
    pdfWindow.location.href = fileURL;
  } catch (error) {
    error.response && enqueueSnackbar(JSON.stringify(error.response.data), ERR_TOP_CENTER);
  }
}

export async function hdDownloadDoc(doc, path, fileName, enqueueSnackbar) {
  try {
    const response = await axios.get(path, { responseType: "blob", params: { _id: doc._id } });
    download(response.data, fileName, "application/pdf");
  } catch (error) {
    error.response && enqueueSnackbar(JSON.stringify(error.response.data), ERR_TOP_CENTER);
  }
}

export async function hdHide({ _id, allDocs, setAllDocs, eq, path }) {
  try {
    await axios.patch(path, { _id, status: FILTER.HIDE });
    const clone = [...allDocs];
    clone.find((doc) => doc._id === _id).status = FILTER.HIDE;
    setAllDocs(clone);
    eq("Đã ẩn", { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
  } catch (error) {
    console.error(error);
    error.response && eq(JSON.stringify(error.response.data), ERR_TOP_CENTER);
  }
}

export async function hdUnhide({ _id, allDocs, setAllDocs, eq, path }) {
  try {
    await axios.patch(path, { _id, status: FILTER.UNHIDE });
    const clone = [...allDocs];
    clone.find((doc) => doc._id === _id).status = FILTER.UNHIDE;
    setAllDocs(clone);
    eq("Đã bỏ ẩn", { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
  } catch (error) {
    console.error(error);
    error.response && eq(JSON.stringify(error.response.data), ERR_TOP_CENTER);
  }
}
