export enum Color {
  RED = "red",
  ORANGE = "orange",
  YELLOW = "yellow",
  OLIVE = "olive",
  GREEN = "green",
  TEAL = "teal",
  BLUE = "blue",
  VIOLOET = "violet",
  PURPLE = "purple",
  BROWN = "brown",
  GREY = "grey",
  BLACK = "black",
}

export enum AttachmentTypes {
  IMAGE = "image",
  DRAWING = "drawing",
  TEXT = "text",
}
