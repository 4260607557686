import { AttachmentTypes } from "src/entities";
import { readAsImage } from "src/utils/asyncReader";

export function dataURLtoFile(dataurl: any, filename: any) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

// AttachmentBase:
// id,
// pageIndex,
// type: AttachmentTypes.IMAGE,
// width,
// height,
// x: 0,
// y: 0,

export async function convertImageToAttachment(url: string, pageIndex) {
  try {
    const img = await readAsImage(url as string);
    const id = Date.now();
    const { width, height } = img;

    const imageAttachment = {
      id,
      pageIndex,
      type: AttachmentTypes.IMAGE,
      width,
      height,
      x: 0,
      y: 0,
      url,
    };
    return imageAttachment;
  } catch (error) {
    console.log("Failed to load image", error);
    throw new Error("Failed to load image");
  }
}

export function convertDrawingToAttachment(drawing, pageIndex) {
  if (!drawing) return;
  const id = Date.now();
  const newDrawingAttachment = {
    id,
    pageIndex,
    type: AttachmentTypes.DRAWING,
    ...drawing,
    x: 0,
    y: 0,
    scale: 1,
  };
  return newDrawingAttachment;
}

export function genTextAttachment(pageIndex) {
  const id = Date.now();
  const newTextAttachment = {
    id,
    pageIndex,
    type: AttachmentTypes.TEXT,
    x: 0,
    y: 0,
    width: 215,
    height: 80,
    text: "Enter Text Here",
    style: { fontSize: 14, lineHeight: 1.2, fontFamily: "Times-Roman" },
  };

  return newTextAttachment;
}



export function genSignaturePlaceholder(email, pageIndex) {
  const id = Date.now();
  const newSignaturePlaceholder = {
    id,
    email,
    pageIndex,
    x: 0,
    y: 0,
    width: 156,
    height: 90,
  };

  return newSignaturePlaceholder;
}
