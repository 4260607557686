import { useMediaQuery } from "@mui/material";
import React, { useState, createRef, useEffect } from "react";
import { Modal, Button, Menu, Dropdown, Label } from "semantic-ui-react";
import { Color } from "../../entities";
import styles from "./styles.module.css";

interface Props {
  open: boolean;
  dismiss: () => void;
  confirm: (drawing?: { width: number; height: number; originWidth?: number; originHeight?: number; path: string; strokeWidth: number; stroke: string }) => void;
  drawing?: DrawingAttachment;
}

export const DrawingModal = ({ open, dismiss, confirm, drawing }: Props) => {
  const match = useMediaQuery("only screen and (max-width: 767px");

  const svgRef = createRef<SVGSVGElement>();
  const [paths, setPaths] = useState<Array<[string, number, number]>>([]);
  const [path, setPath] = useState((drawing && drawing.path) || "");
  const [svgX, setSvgX] = useState(0);
  const [svgY, setSvgY] = useState(0);
  const [minX, setMinX] = useState(Infinity);
  const [maxX, setMaxX] = useState(0);
  const [minY, setMinY] = useState(Infinity);
  const [maxY, setMaxY] = useState(0);
  const [mouseDown, setMouseDown] = useState(false);
  const [strokeWidth, setStrokeWidth] = useState(2);
  const [stroke, setStroke] = useState(Color.BLACK);
  const [strokeDropdownOpen, setStrokeDropdownOpen] = useState(false);

  useEffect(() => {
    const svg = svgRef.current;
    if (!svg) return;
    const { x, y } = svg.getBoundingClientRect();
    setSvgX(x);
    setSvgY(y);
  }, [svgRef]);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setMouseDown(true);

    const x = event.clientX - svgX;
    const y = event.clientY - svgY;
    setMinX(Math.min(minX, x));
    setMaxX(Math.max(maxX, x));
    setMinY(Math.min(minY, y));
    setMaxY(Math.max(maxY, y));
    setPath(path + `M${x},${y}`);
    setPaths([...paths, ["M", x, y]]);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!mouseDown) return;

    const x = event.clientX - svgX;
    const y = event.clientY - svgY;
    setMinX(Math.min(minX, x));
    setMaxX(Math.max(maxX, x));
    setMinY(Math.min(minY, y));
    setMaxY(Math.max(maxY, y));
    setPath(path + `L${x},${y}`);
    setPaths([...paths, ["L", x, y]]);
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setMouseDown(false);
  };

  const resetDrawingBoard = () => {
    setPaths([]);
    setPath("");
    setMinX(Infinity);
    setMaxX(0);
    setMinY(Infinity);
    setMaxY(0);
    setStrokeWidth(2);
    setStroke(Color.BLACK);
  };

  const handleDone = () => {
    if (!paths.length) {
      // confirm();
      return closeModal();
;
    }

    const boundingWidth = maxX - minX;
    const boundingHeight = maxY - minY;

    const dx = -(minX - 10);
    const dy = -(minY - 10);

    confirm({
      stroke,
      strokeWidth,
      width: boundingWidth + 20,
      height: boundingHeight + 20,
      originWidth: boundingWidth + 20,
      originHeight: boundingHeight + 20,
      path: paths.reduce((fullPath, lineItem) => `${fullPath}${lineItem[0]}${lineItem[1] + dx}, ${lineItem[2] + dy}`, ""),
    });

    closeModal();
  };

  const closeModal = () => {
    resetDrawingBoard();
    dismiss();
  };

  const clear = () => {
    resetDrawingBoard();
  };

  const strokeSizes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleStrokeSelect = (color: Color) => () => {
    setStroke(color);
    setStrokeDropdownOpen(false);
  };

  return (
    <Modal size="small" dimmer="inverted" open={open} onClose={closeModal} className={styles.no_underline}>
      <Modal.Header>Vẽ chữ ký</Modal.Header>
      <Modal.Content>
        <Menu size="tiny">
          <Menu.Item header>Cỡ chữ</Menu.Item>
          {/* <Menu.Item><Icon name="undo" /></Menu.Item>
                    <Menu.Item><Icon name="redo" /></Menu.Item> */}
          <Menu.Menu>
            <Dropdown item text={`${strokeWidth}`}>
              <Dropdown.Menu>
                {strokeSizes.map((size) => (
                  <Dropdown.Item key={size} selected={size === strokeWidth} onClick={() => setStrokeWidth(size)}>
                    {size}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Menu.Item header>Màu chữ</Menu.Item>

            <Dropdown item trigger={<Label color={stroke} />} onClick={() => setStrokeDropdownOpen(true)} onBlur={() => setStrokeDropdownOpen(false)}>
              <Dropdown.Menu open={strokeDropdownOpen}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    padding: 5,
                  }}>
                  {Object.values(Color).map((color, index) => (
                    <div style={{ margin: 2.5 }} key={index}>
                      <Label color={color} onClick={handleStrokeSelect(color)} />
                    </div>
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
        <div style={{ marginTop: 16 }}></div>
        <div
          onTouchStart={(e) => {
            e.preventDefault();
          }}
          onTouchMove={(e) => {
            e.preventDefault();
          }}
          onTouchEnd={(e) => {
            e.preventDefault();
          }}
          onPointerDown={handleMouseDown}
          onPointerMove={handleMouseMove}
          onPointerUp={handleMouseUp}
          style={{ border: "dashed 2px black", touchAction: "none" }}>
          <svg
            ref={svgRef}
            style={{
              width: "100%",
              height: "40vh",
            }}>
            <path strokeWidth={strokeWidth} strokeLinejoin="round" strokeLinecap="round" stroke={stroke} fill="none" d={path} />
          </svg>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", paddingBottom: match ? 16 : 0 }}>
          <div style={{ paddingLeft: 8 }}>
            <Button basic color="blue" content="Xóa" onClick={clear} disabled={!paths.length} />
          </div>
          <div>
            <Button color="black" content="Hủy" onClick={closeModal} />
            <span style={{ marginRight: 8 }}></span>
            <Button content="Xong" labelPosition="right" icon="checkmark" onClick={handleDone} positive disabled={!paths.length} />
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  );
};
