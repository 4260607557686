import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { SliceStatus } from "../type";

export interface ProfileState {
  status: SliceStatus;
  _id?: string;
  email?: string;
  name?: string;
  majorSignatureImgSrc?: string;
  majorSignatureSvg?: string;
  majorType?: string;
  minorSignatureImgSrc?: string;
  minorSignatureSvg?: string;
  minorType?: string;
  TOTPSecret?: string;
  TOTPURL?: string;
}

const initialState: ProfileState = {
  status: SliceStatus.Init,
};

export const fetchProfile = createAsyncThunk("profile/fetchProfile", async () => {
  const response = await axios.get("/acc/profile");
  return response.data;
});

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateImgSrc: (state, action) => {
      Object.assign(state, action.payload);
    },
    updateSvg: (state, action) => {
      Object.assign(state, action.payload);
    },
    resetProfile: (state, action) => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProfile.pending, state => {
        state.status = SliceStatus.Loading;
      })
      .addCase(fetchProfile.rejected, state => {
        state.status = SliceStatus.Failed;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        // state.status = SliceStatus.Succeed;
        return { ...action.payload, status: SliceStatus.Succeed };
        // Object.assign({}, action.payload);
        
      });
  },
});

export const { updateImgSrc, updateSvg, resetProfile } = profileSlice.actions;
export default profileSlice.reducer;
