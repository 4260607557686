import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import React, { createRef, useState } from "react";

export default function AskMetadata({ open, hdCancel, hdSubmit }) {
  const [reason, setReason] = useState("Không có");
  const [location, setLocation] = useState("bSign");
  const [OTP, setOTP] = useState(["", "", "", "", "", ""]);
  const [refs] = useState([...Array(6).keys()].map(() => createRef()));

  function hdKeyUp(e, index) {
    if (e.keyCode === 13) return onSubmit();
    if (e.keyCode === 8) {
      const clonedOTP = [...OTP];
      clonedOTP[index] = "";
      if (index > 0) refs[index - 1].current.focus();
      setOTP(clonedOTP);
    }
  }

  function hdChange(e, index) {
    if (!/[0-9]/.test(e.target.value)) return;
    const clonedOTP = [...OTP];
    clonedOTP[index] = e.target.value;
    if (index < 5) {
      refs[index + 1].current.focus();
    }
    setOTP(clonedOTP);
  }

  function onSubmit() {
    hdSubmit({ reason, location, OTP: OTP.join("") });
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={() => hdCancel()}>
      <DialogTitle>Xác thực</DialogTitle>
      <DialogContent>
        <Box mb={2}></Box>
        {/* 
        <TextField autoFocus fullWidth value={reason} onChange={(e) => setReason(e.target.value)} label="Lý do" />

        <Box mb={3}></Box>

        <TextField fullWidth value={location} onChange={(e) => setLocation(e.target.value)} label="Nơi ký" />

        <Box mb={3}></Box> */}

        <Typography align="center" variant="h6">
          Mã OTP{" "}
        </Typography>
        <Box mb={2}></Box>
        <Grid container spacing={2} justifyContent="center">
          {[...Array(6).keys()].map((item, index) => (
            <Grid item key={index}>
              <TextField
                inputProps={{ size: 1 }}
                variant="outlined"
                color="primary"
                value={OTP[index]}
                onKeyUp={(e) => hdKeyUp(e, index)}
                onChange={(e) => hdChange(e, index)}
                inputRef={refs[index]}
                autoFocus={index === 0}
              />
            </Grid>
          ))}
        </Grid>

        <Box mt={3} textAlign="right">
          <Button onClick={hdCancel} style={{ marginRight: "16px" }}>
            Hủy bỏ
          </Button>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            OK
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
