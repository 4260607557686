import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useAppDispatch } from "src/redux/hooks";
import { SliceStatus } from "src/redux/type";
import CreateSignature from "./CreateSignature";
import DisplaySignature from "./DisplaySignature";
import { hdSaveSvgMajor, hdSaveSvgMinor, hdUploadMajorSignature, hdUploadMinorSignature } from "./handler";

export default function Signature({ profile }) {
  const [editingMajor, setEditingMajor] = useState(false);
  const [editingMinor, setEditingMinor] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const dp = useAppDispatch();

  return (
    <>
      {profile.status === SliceStatus.Succeed && (profile.majorSignatureImgSrc || profile.majorSignatureSvg) && editingMajor === false ? (
        <DisplaySignature title="Chữ ký" imgSrc={profile.majorSignatureImgSrc} svg={profile.majorSignatureSvg} setEditing={setEditingMajor} type={profile.majorType} isMajor={true}/>
      ) : (
        <CreateSignature
          title="Chữ ký"
          hdUploadImage={(e) => hdUploadMajorSignature(e, dp, enqueueSnackbar, setEditingMajor)}
          editing={editingMajor}
          setEditing={setEditingMajor}
          hdSaveSvg={(drawing) => hdSaveSvgMajor(drawing, dp, enqueueSnackbar, setEditingMajor)}
        />
      )}

      {/* <Box mt={2}></Box>

      {profile.status === SliceStatus.Succeed && (profile.minorSignatureImgSrc || profile.minorSignatureSvg) && editingMinor === false ? (
        <DisplaySignature title="Chữ ký nháy" imgSrc={profile.minorSignatureImgSrc} svg={profile.minorSignatureSvg} setEditing={setEditingMinor} type={profile.minorType} />
      ) : (
        <CreateSignature
          title="Chữ ký nháy"
          hdUploadImage={(e) => hdUploadMinorSignature(e, dp, enqueueSnackbar, setEditingMinor)}
          editing={editingMinor}
          setEditing={setEditingMinor}
          hdSaveSvg={(drawing) => hdSaveSvgMinor(drawing, dp, enqueueSnackbar, setEditingMinor)}
        />
      )} */}
    </>
  );
}
