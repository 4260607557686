import { Box, Button, ButtonGroup, TablePagination, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { FILTER } from "./constance";
import TablePaginationActions from "./TablePaginationActions";

function filterDocs(doc, filterMode) {
  if (filterMode === FILTER.ALL) return true;
  if (filterMode === FILTER.HIDE) return doc.status === FILTER.HIDE;
  if (filterMode === FILTER.UNHIDE) return !doc.status || doc.status === FILTER.UNHIDE;
}

export function useFilter({ allDocs }) {
  const match = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [filterMode, setFilterMode] = useState(FILTER.UNHIDE);
  const docs = allDocs === null ? [] : allDocs.filter((doc) => filterDocs(doc, filterMode));
  const filterBtnGroup = (
    <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "center" }}>
      <Typography sx={{ mr: 1, fontSize: "0.85rem", color: "black", py: 0.5 }}>Chọn trạng thái:</Typography>
      <ButtonGroup variant="outlined" size="small">
        <Button variant={filterMode === FILTER.UNHIDE ? "contained" : "outlined"} onClick={() => setFilterMode(FILTER.UNHIDE)}>
          Không ẩn
        </Button>
        <Button variant={filterMode === FILTER.HIDE ? "contained" : "outlined"} onClick={() => setFilterMode(FILTER.HIDE)}>
          Đã ẩn
        </Button>
        <Button variant={filterMode === FILTER.ALL ? "contained" : "outlined"} onClick={() => setFilterMode(FILTER.ALL)}>
          Tất cả
        </Button>
      </ButtonGroup>
    </Box>
  );
  return { filterMode, docs, filterBtnGroup };
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    flexWrap: "wrap",
    paddingLeft: 0,
    justifyContent: "center",
  },
}));

export function usePagination({ docs, dfRowsPerPage = 10 }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(dfRowsPerPage);
  const emptyRows = docs !== null && page >= 0 && docs.length ? Math.max(0, (1 + page) * rowsPerPage - docs.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginationComponent = (
    <TablePagination
      classes={{ toolbar: classes.toolbar }}
      rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
      labelRowsPerPage="Số dòng mỗi trang"
      // colSpan={pagiColSpan}
      count={docs.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
      sx={{ "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": { margin: 0 } }}
    />
  );

  return { page, rowsPerPage, emptyRows, paginationComponent };
}
