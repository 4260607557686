import DoneIcon from "@mui/icons-material/Done";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatLocalString2Line } from "../../utility/formatter";
import { FILTER } from "../DocumentList/constance";
import { hdClickSign, hdDownloadDoc, hdHide, hdOpenPdfInNewTab, hdUnhide } from "../DocumentList/handler";
import { useFilter, usePagination } from "../DocumentList/hooks";
import { shortenFileName } from "../DocumentList/utils";
import { PdfSignatureField } from "./constance";

export default function Endterm({ signing, setSigning }) {
  const [allDocs, setAllDocs] = useState(null);
  const { docs, filterBtnGroup } = useFilter({ allDocs });
  const { page, rowsPerPage, emptyRows, paginationComponent } = usePagination({ docs });

  const teacherId = useSelector((state) => state.profile.email);
  const [dialog, setDialog] = useState(null);
  const { enqueueSnackbar: eq } = useSnackbar();

  async function fetchDocs() {
    const response = await axios.get("/grade/endterm/my-docs");
    const _docs = response.data.reverse();
    setAllDocs(_docs);
  }

  useEffect(() => {
    if (signing === false) fetchDocs();
  }, [signing]);

  function shouldDisableSign(doc) {
    const myFields = doc.signers.filter((s) => s.email === teacherId).map((s) => s.fieldName);
    const signedFields = doc.signatures.map((s) => s.fieldName);
    const remainFields = myFields.filter((f) => !signedFields.includes(f));
    return remainFields.length === 0;
  }

  return (
    <div>
      {dialog}

      {allDocs === null && <CircularProgress sx={{ m: 2 }} size="1.5rem" />}

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Kì học</TableCell>
              <TableCell>Mã lớp thi</TableCell>
              <TableCell>Thời gian tạo</TableCell>
              <TableCell>Người chấm thi</TableCell>
              <TableCell>Người vào điểm</TableCell>
              <TableCell>Đơn vị phụ trách</TableCell>
              <TableCell align="center">Xem</TableCell>
              <TableCell align="center">Ký</TableCell>
              <TableCell align="center">Tải về</TableCell>
              <TableCell align="center">Ẩn/Hiện</TableCell>
            </TableRow>
          </TableHead>

          {allDocs !== null && allDocs.length > 0 && (
            <TableBody>
              {(rowsPerPage > 0 ? docs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : docs).map((doc, index) => (
                <TableRow key={doc._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{doc.semester}</TableCell>
                  <TableCell>{doc.examClassId}</TableCell>

                  <TableCell>{formatLocalString2Line(doc.createTimestamp)}</TableCell>

                  <TableCell>
                    {(() => {
                      if (!doc.markerId) return "";
                      const signature = doc.signatures.find((sig) => sig.fieldName === PdfSignatureField.MARKER);
                      if (!signature) return <Box sx={{ mb: "4px" }}>{doc.markerId.split("@")[0]}</Box>;
                      const timeStr = new Date(signature.timestamp).toLocaleString();
                      return (
                        <Box sx={{ display: "flex", alignItems: "end", mb: "4px" }}>
                          {doc.markerId.split("@")[0]}{" "}
                          <Tooltip title={`Đã ký vào: ${timeStr}`} placement="top">
                            <DoneIcon size="small" color="success" sx={{ ml: 1 }} />
                          </Tooltip>
                        </Box>
                      );
                    })()}
                  </TableCell>

                  <TableCell>
                    {(() => {
                      const signature = doc.signatures.find((sig) => sig.fieldName === PdfSignatureField.INPUTTER);
                      if (!signature) return <Box sx={{ mb: "4px" }}>{doc.inputterId.split("@")[0]}</Box>;
                      const timeStr = new Date(signature.timestamp).toLocaleString();
                      return (
                        <Box sx={{ display: "flex", alignItems: "end", mb: "4px" }}>
                          {doc.inputterId.split("@")[0]}{" "}
                          <Tooltip title={`Đã ký vào: ${timeStr}`} placement="top">
                            <DoneIcon size="small" color="success" sx={{ ml: 1 }} />
                          </Tooltip>
                        </Box>
                      );
                    })()}
                  </TableCell>

                  <TableCell>
                    {doc.managerIds.map((email) => {
                      const signature = doc.signatures.find((sig) => sig.email === email && sig.fieldName === PdfSignatureField.MANAGER);
                      if (!signature)
                        return (
                          <Box key={email} sx={{ mb: "4px" }}>
                            {email.split("@")[0]}
                          </Box>
                        );
                      const timeStr = new Date(signature.timestamp).toLocaleString();
                      return (
                        <Box key={email} sx={{ display: "flex", alignItems: "end", mb: "4px" }}>
                          {email.split("@")[0]}{" "}
                          <Tooltip title={`Đã ký vào: ${timeStr}`} placement="top">
                            <DoneIcon size="small" color="success" sx={{ ml: 1 }} />
                          </Tooltip>
                        </Box>
                      );
                    })}
                  </TableCell>

                  <TableCell align="center">
                    <a
                      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                      href="/"
                      onClick={(e) => hdOpenPdfInNewTab(e, doc, eq, "/grade/endterm/current-pdf-file")}>
                      <OpenInNewIcon size="small" />
                    </a>
                  </TableCell>

                  <TableCell align="center">
                    <IconButton disabled={shouldDisableSign(doc)} onClick={() => hdClickSign(doc._id, setDialog, eq, setSigning, "/grade/endterm/sign-doc")}>
                      <DriveFileRenameOutlineIcon />
                    </IconButton>
                  </TableCell>

                  <TableCell align="center">
                    <IconButton
                      onClick={() => {
                        const downloadFileName = doc.signatures.length + "-" + doc.totalSignature + "-Signed_" + doc.classId + "-" + doc.originalFileName;
                        const path = "/grade/endterm/current-pdf-file";
                        hdDownloadDoc(doc, path, downloadFileName, eq);
                      }}>
                      <FileDownloadOutlinedIcon />
                    </IconButton>
                  </TableCell>

                  <TableCell align="center">
                    {!doc.status || doc.status === FILTER.UNHIDE ? (
                      <Tooltip placement="right" title="Ẩn đi">
                        <IconButton onClick={() => hdHide({ _id: doc._id, allDocs, setAllDocs, eq, path: "/grade/endterm/update-status" })}>
                          <VisibilityOffIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip placement="right" title="Bỏ ẩn">
                        <IconButton onClick={() => hdUnhide({ _id: doc._id, allDocs, setAllDocs, eq, path: "/grade/endterm/update-status" })}>
                          <VisibilityIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 50 * emptyRows }}>
                  <TableCell colSpan={11} />
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {allDocs !== null && allDocs.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "center", paddingX: 2 }}>
          <Box sx={{ py: 1 }}>{filterBtnGroup}</Box>
          <Box>{paginationComponent}</Box>
        </Box>
      )}

      {allDocs !== null && allDocs.length === 0 && (
        <Box p={2}>
          <Typography variant="h6">Danh sách rỗng</Typography>
        </Box>
      )}
    </div>
  );
}
