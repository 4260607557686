import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Button, DialogActions, Grid } from "@mui/material";
import React from "react";

export default function QRCodeDialog({ close, secret, qrURL }) {
  return (
    <Dialog open={true} maxWidth="sm" fullWidth onClose={() => close()}>
      <DialogTitle>
        <Typography variant="h4">TOTP QR CODE</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container wrap="nowrap">
          <Grid item xs={12} sm={6} zeroMinWidth>
            <Box textAlign="center">
              <Typography variant="h5" style={{ overflowWrap: "break-word" }}>
                Quét mã QR code
              </Typography>
              <img src={qrURL} alt="QRCode" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} zeroMinWidth>
            <Box textAlign="center">
              <Typography variant="h5">Hoặc nhập mã này</Typography>
              <Box mb={1}></Box>
              <Typography style={{ overflowWrap: "break-word" }}>{secret}</Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box textAlign={"right"}>
          <Button variant="outlined" onClick={() => close()}>
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
