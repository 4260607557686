import DangerousIcon from "@mui/icons-material/Dangerous";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { Rnd } from "react-rnd";
import { updateAttachment } from "../../redux/slices/attachment";
import { hdRemoveAttachment, hdStopDrag, hdStopResize, style } from "./shared";

export default function DrawingDisplayerV2({ attachment }) {
  const dp = useDispatch();

  return (
    <Rnd
      bounds="parent"
      style={style}
      size={{ width: attachment.width, height: attachment.height }}
      position={{ x: attachment.x, y: attachment.y }}
      onDragStop={(e, data) => hdStopDrag(e, data, dp, attachment.id)}
      onResizeStop={(e, direction, ref, delta, position) => {
        hdStopResize(e, direction, ref, delta, position, attachment.id, dp);
        const w = ref.style.width;
        const width = typeof w === "number" ? w : Number(w.split("px")[0]);
        dp(updateAttachment({ id: attachment.id, scale: width / attachment.originWidth }));
      }}>
      <div style={{ position: "absolute", top: 2, right: 2 }}>
        <IconButton size="small" onClick={() => hdRemoveAttachment(attachment.id, dp)}>
          <DangerousIcon color="error" />
        </IconButton>
      </div>

      <div
        style={{
          height: "100%",
          backgroundImage: `url(${getSvgUri(attachment)})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      />
    </Rnd>
  );
}

export function getSvgUri(attachment) {
  // console.log({ attachment });
  const svgStr = `<svg xmlns:dc="http://purl.org/dc/elements/1.1/"
  xmlns:cc="http://creativecommons.org/ns#"
  xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
  xmlns:svg="http://www.w3.org/2000/svg"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 ${attachment.originWidth} ${attachment.originHeight}" preserveAspectRatio="xMinYMin meet">
  <path strokeWidth="${attachment.strokeWidth}" strokeLinecap="round" strokeLinejoin="round" stroke="${attachment.stroke}" fill="none" d="${attachment.path}" />
  </svg>`;
  const b64 = btoa(svgStr);
  return "data:image/svg+xml;base64," + b64;
}
