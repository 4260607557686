import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useTheme } from "@mui/material";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { setLocalToken, setRemember } from "src/utility/mng-token";
import { fetchProfile } from "../../redux/slices/profile-slice";

export default function SignUp() {
  const [state, setState] = useState({
    email: "",
    password: "",
    repassword: "",
  });

  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const theme = useTheme();
  const dp = useDispatch();

  async function hdSubmit(e) {
    e.preventDefault();
    try {
      const response = await axios.post("/acc/signup", state);
      const result = response.data;
      setLocalToken(result.token);
      setRemember(true);
      setErrors(null);
      setSuccess("Đăng ký tài khoản thành công!");
      dp(fetchProfile());
      setTimeout(() =>  navigate("/"), 1000);
    } catch (error) {
      setErrors(error.response.data);
    }
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      onKeyPress={(e) => {
        if (e.key === "Enter") hdSubmit();
      }}>
      <CssBaseline />
      <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Avatar sx={{ margin: 1, backgroundColor: theme.palette.primary.main }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h4">
          Đăng ký
        </Typography>
        <div>
          {success ? (
            <Alert sx={{ mt: 3 }} severity="success">
              {success}
            </Alert>
          ) : null}
        </div>
        <form
          style={{
            width: "100%", // Fix IE 11 issue.
            marginTop: 24,
          }}
          noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email"
                value={state.email}
                onChange={(e) => {
                  setState({ ...state, email: e.target.value });
                  setErrors(null);
                }}
                error={Boolean(errors?.email)}
                helperText={errors?.email}
                disabled={Boolean(success)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Mật khẩu"
                type="password"
                id="password"
                value={state.password}
                onChange={(e) => {
                  setState({ ...state, password: e.target.value });
                  setErrors(null);
                }}
                error={Boolean(errors?.password)}
                helperText={errors?.password}
                disabled={Boolean(success)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Nhập lại mật khẩu"
                type="password"
                id="rePassword"
                value={state.repassword}
                onChange={(e) => {
                  setState({ ...state, repassword: e.target.value });
                  setErrors(null);
                }}
                error={Boolean(errors?.repassword)}
                helperText={errors?.repassword}
                disabled={Boolean(success)}
              />
            </Grid>
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" style={{ margin: "24px 0 16px" }} onClick={hdSubmit}>
            Đăng ký
          </Button>

          <Box textAlign="right">
            <Link component={RouterLink} to="/sign-in">
              Bạn đã có tài khoản rồi? Đăng nhập
            </Link>
          </Box>
        </form>
      </Box>
      <Box mt={5}>{/* <Copyright /> */}</Box>
    </Container>
  );
}
