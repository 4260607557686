import { Container, Grid, Typography } from "@mui/material";
import _11 from "./images/single-sign/1.png";
import _12 from "./images/single-sign/2.png";
import _13 from "./images/single-sign/3.png";
import _14 from "./images/single-sign/4.png";
import _15 from "./images/single-sign/5.png";
import _16 from "./images/single-sign/6.png";
import _17 from "./images/single-sign/7.png";
import _18 from "./images/single-sign/8.png";

import _21 from "./images/verify/6.png";
import _22 from "./images/verify/7.png";
import _23 from "./images/verify/8.png";
import _24 from "./images/verify/2.png";
import _25 from "./images/verify/5.png";

import _31 from "./images/multi-sign/3.png";
import _32 from "./images/multi-sign/4.png";
import _33 from "./images/multi-sign/5.png";
import _34 from "./images/multi-sign/7.png";

import _41 from "./images/grades/1.png";

import videoSrc from "./demo-bSign.webm";

import styles from "./style.module.css";
import Navbar from "../../components/Navbar";

export default function InstructionDoc() {
  return (
    <>
      <Navbar />
      <Container fixed>
        <div style={{ fontFamily: "sans-serif", paddingTop: 32, paddingBottom: 16 }}>
          <Typography align="center" variant="h4">
            Hướng dẫn sử dụng hệ thống chữ ký số tài liệu PDF (bSign v1.0)
          </Typography>
          <div style={{ textAlign: "center" }}>
            {/* <Typography variant="h5">Video demo</Typography> */}
            <video controls src={videoSrc} style={{ width: "100%" }}></video>
          </div>

          {/* Feature 1 */}
          <div>
            <Typography variant="h5">Chức năng 1: Đơn ký tài liệu</Typography>
            <ul style={{ marginTop: 0 }}>
              <li>Bước 0.1: Chọn mục Đơn ký:</li>
              <li>Bước 0.2: Thiết lập hình ảnh cho chữ ký chính và chữ ký nháy (có thể vẽ tay hoặc upload ảnh)</li>
              <li>Bước 1: Chọn mục Kéo thả, hoặc nhấn để chọn file PDF cần ký</li>
              <li>Bước 2: (Tùy chọn) Chèn chữ ký chính, chữ ký nháy, văn bản hoặc ô chữ ký số vào vị trí thích hợp</li>
              <li>Bước 3: Nhấn nút “Ký”, nhập mã OTP trên ứng dụng eHust</li>
              <li>Bước 4: Download tài liệu đã ký</li>
            </ul>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <div>
                  <img src={_11} alt="" style={{ maxWidth: "100%" }} />
                  <Typography className={styles.caption}>Hình 1: 1-Mục đơn ký; 2-Thiết lập chữ ký; 3-Khung kéo thả file PDF</Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <div>
                  <img src={_12} alt="" style={{ maxWidth: "100%" }} />
                  <Typography className={styles.caption}>Hình 2: 1-Vẽ chữ ký; 2-Khung vẽ chữ ký; 3-Lưu</Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <div>
                  <img src={_13} alt="" style={{ maxWidth: "100%" }} />
                  <Typography className={styles.caption}>Hình 3: 1-Tải ảnh chữ ký; 2-Chọn ảnh chữ ký;</Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <div>
                  <img src={_14} alt="" style={{ maxWidth: "100%" }} />
                  <Typography className={styles.caption}>Hình 4: Kéo thả file PDF cần ký</Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <div>
                  <img src={_15} alt="" style={{ maxWidth: "100%" }} />
                  <Typography className={styles.caption}>Hình 5: 1-Tùy chọn chèn; 2-Chữ ký chính; 3-Chữ ký nháy; 4-Văn bản; 5-Ô chữ ký số</Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <div>
                  <img src={_16} alt="" style={{ maxWidth: "100%" }} />
                  <Typography className={styles.caption}>Hình 6: 1-Nhấn nút Ký; 2-Nhập mã OTP</Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <div>
                  <img src={_17} alt="" style={{ maxWidth: "100%" }} />
                  <Typography className={styles.caption}>Hình 7: 1-Thông báo ký thành công; 2-Lưu file đã ký</Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <div>
                  <img src={_18} alt="" style={{ maxWidth: "100%" }} />
                  <Typography className={styles.caption}>Hình 8: 1-Tài liệu đơn ký của tôi;2-Tài liệu đồng ký tôi là tác giả; 3-Tài liệu đồng ký tôi được mời cùng ký</Typography>
                </div>
              </Grid>
            </Grid>
          </div>

          {/* Feature 2 */}
          <div>
            <Typography variant="h5">Chức năng 2: Xác thực</Typography>
            <Typography>Cách 1: Sử dụng website bSign</Typography>
            <ul style={{ marginTop: 0 }}>
              <li>Bước 1: Chọn mục Xác thực</li>
              <li>Bước 2: Upload tài liệu PDF đã ký</li>
              <li>Bước 3: Xem mục thông tin xác thực</li>
              <li>Bước 4: Xem thông tin chi tiết từng chữ ký</li>
            </ul>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <div>
                  <img src={_21} alt="" style={{ maxWidth: "100%" }} />
                  <Typography className={styles.caption}>Hình 9: 1-Upload file PDF đã ký</Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <div>
                  <img src={_22} alt="" style={{ maxWidth: "100%" }} />
                  <Typography className={styles.caption}>Hình 10: 1-Kết quả xác thực; 2-Xem chi tiết</Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <div>
                  <img src={_23} alt="" style={{ maxWidth: "100%" }} />
                  <Typography className={styles.caption}>Hình 11: 1-Chi tiết chứng chỉ người ký; 2-Chi tiết thông tin chữ ký</Typography>
                </div>
              </Grid>
            </Grid>

            <Typography>Cách 2: Sử dụng ứng dụng Adobe Acrobat Reader:</Typography>
            <ul style={{ marginTop: 0 }}>
              <li>Bước 1: Mở file đã ký;</li>
              <li>
                <div>Bước 2: Thêm “HUST Root CA” vào danh sách “Trusted Certificates” (bước này chỉ phải thực hiện 1 lần đầu tiên duy nhất):</div>
                <div>
                  {` Nhấn “Signature Panel” => Nhấn xem thông tin chi tiết chữ ký => Nhấn xem “Signature Details” => Nhấn Certificate Details => Lựa chọn “HUST Root CA” => Chọn tab: “Trust”
            => Add to Trusted Certificates => OK => OK => OK`}
                </div>
              </li>
              <li>{`Bước 3: Đóng ứng dụng => Mở lại`}</li>
              <li>{`Bước 4: Xem thông tin xác thực`}</li>
            </ul>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <div>
                  <img src={_24} alt="" style={{ maxWidth: "100%" }} />
                  <Typography className={styles.caption}>
                    Hình 12: 1-Mở “Signature Panel”; 2- Xem thông tin chữ ký; 3-Xem chi tiết chữ ký; 4-Xem thông tin chứng chỉ; 5-Lựa chọn xem thông tin Hust Root CA; 6-Chọn tab
                    Trust; 7: Nhấn “Add to Trusted Certificates”; 8- Xác nhận “OK”; 9-OK
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <div>
                  <img src={_25} alt="" style={{ maxWidth: "100%" }} />
                  <Typography className={styles.caption}>Hình 13: 1-Thông tin xác thực tài liệu đã ký; 2-Thông tin chi tiết về chữ ký số</Typography>
                </div>
              </Grid>
            </Grid>
          </div>

          {/* Feature 3 */}
          <div>
            <Typography variant="h5">Chức năng 3: Đồng ký</Typography>
            <ul style={{ marginTop: 0 }}>
              <li>Bước 1: Upload tài liệu cần đồng ký</li>
              <li>Bước 2: Thêm người cùng ký</li>
              <li>Bước 3: Xác định vị trí ô chữ ký số cho từng người</li>
              <li>Bước 4: Nhấn “Tạo đồng ký”</li>
              <li>Bước 5: Các người được mời ký thực hiện đồng ký tài liệu</li>
              <li>Bước 6: Download file đã ký</li>
              <li>Bước 7: Xác thực file đã ký</li>
            </ul>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <div>
                  <img src={_31} alt="" style={{ maxWidth: "100%" }} />
                  <Typography className={styles.caption}>
                    {`Hình 14:1-Upload file PDF; 2-Thêm người cùng ký; 3-Thêm ô chữ ký số cho từng người; 4-Xác định vị trí ô chữ ký số`}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <div>
                  <img src={_32} alt="" style={{ maxWidth: "100%" }} />
                  <Typography className={styles.caption}>{`Hình 15: 1-Nhấn tạo đồng ký; 2-Nhập OTP`}</Typography>
                </div>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <div>
                  <img src={_33} alt="" style={{ maxWidth: "100%" }} />
                  <Typography
                    className={
                      styles.caption
                    }>{`Hình 16: 1-Thông báo tạo tài liệu đồng ký thành công; 2-Mục danh sách tài liệu; 3-Tab Đồng ký của tôi; 4-Link xem tài liệu gốc; 5-Danh sách người cùng ký; 6-Download tài liệu với các chữ ký đã có hiện tại;`}</Typography>
                </div>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <div>
                  <img src={_34} alt="" style={{ maxWidth: "100%" }} />
                  <Typography
                    className={
                      styles.caption
                    }>{`Hình 17: 1-Người được mời ký đăng nhập hệ thống; 2-Chọn mục danh sách tài liệu; 3-Tab Được mời cùng ký; 4-Nhấn “Ký” và nhập OTP; 5-Thông báo ký`}</Typography>
                </div>
              </Grid>
            </Grid>
          </div>

          {/* Feature 4 */}
          <div>
            {/* Feature 3 */}
            <Typography variant="h5">Chức năng 4: Ký bảng điểm</Typography>
            <ul style={{ marginTop: 0 }}>
              <li>Bước 1: Chọn mục Ký bảng điểm</li>
              <li>Bước 2: Chọn Giữa kỳ hoặc Cuối kỳ</li>
              <li>{"Bước 3: Tìm & xem bảng điểm trước khi ký"}</li>
              <li>Bước 4: Ký</li>
              <li>Bước 5: Nhập mã TOTP</li>
              <li>Bước 6: Thông báo kết quả ký thành công (hoặc báo lỗi nếu có)</li>
            </ul>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <div>
                  <img src={_41} alt="" style={{ maxWidth: "100%" }} />
                  <Typography
                    className={
                      styles.caption
                    }>{`Hình 18: 1-Mục ký bảng điểm; 2-Giữa kỳ; 3-Cuối kỳ; 4-Xem bảng điểm; 5-Ký; 6-Download bảng điểm đã ký; 7-Ẩn/Hiện dòng; 8-Chọn chế độ filter; 9-Phân trang`}</Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </>
  );
}
