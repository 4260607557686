import { Box } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import DocumentList from "./pages/DocumentList";
import GradeList from "./pages/GradeList";
import InstructionDoc from "./pages/InstructionDoc";
import MultiSign from "./pages/MultiSign";
import Sign from "./pages/Sign";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Verify from "./pages/Verify";
import { getToken } from "./utility/mng-token";

function App() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Header />
      <Routes>
        <Route path="/sign-in" element={<SignIn />}></Route>
        <Route path="/sign-up" element={<SignUp />}></Route>
        <Route
          path="/esign"
          element={
            <RequireAuth>
              <Sign />
            </RequireAuth>
          }
        />
        <Route
          path="/verify"
          element={
            <RequireAuth>
              <Verify />
            </RequireAuth>
          }
        />
        <Route
          path="/multi-signatures"
          element={
            <RequireAuth>
              <MultiSign />
            </RequireAuth>
          }
        />
        <Route
          path="/documents"
          element={
            <RequireAuth>
              <DocumentList />
            </RequireAuth>
          }
        />
        <Route
          path="/grades"
          element={
            <RequireAuth>
              <GradeList />
            </RequireAuth>
          }
        />
        <Route
          path="/instruction-doc"
          element={
            <RequireAuth>
              <InstructionDoc />
            </RequireAuth>
          }
        />
        <Route path="/" element={<Redirector />} />
      </Routes>
      <Footer />
    </Box>
  );
}

function RequireAuth({ children }) {
  let auth = getToken();
  if (!auth) {
    return <Navigate to="/sign-in" replace />;
  }
  return children;
}

function Redirector() {
  let to = getToken() ? "/esign" : "/sign-in";
  return <Navigate to={to} />;
}

export default App;
