import { Box, Tabs, Tab, Container, Paper, AppBar, Backdrop, Typography, CircularProgress, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import MyMultiSignDocuments from "./MyMultiSignDocuments";
import MySignRequests from "./MySignRequests";
import MySingleSignedDocs from "./MySingleSignedDocs";
import SwipeableViews from "react-swipeable-views";

export default function DocumentList() {
  const match = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  let [searchParams, setSearchParams] = useSearchParams();
  const spTabIndex = searchParams.get("tabIndex");
  const [tabIndex, setTabIndex] = useState(Number(spTabIndex) || 0);

  const [signing, setSigning] = useState(false);

  return (
    <>
      <Navbar />
      <Box style={{ backgroundColor: "#f1f1f1", flexGrow: "1" }}>
        {signing && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={signing === true}>
            <Typography variant="h5">Đang ký...</Typography>
            <CircularProgress color="inherit" sx={{ ml: 1 }} />
          </Backdrop>
        )}

        <Container fixed sx={{ py: 2 }}>
          <Paper>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <AppBar position="static">
                <Tabs
                  indicatorColor="secondary"
                  textColor="inherit"
                  centered
                  variant={match ? "scrollable" : ""}
                  scrollButtons={match}
                  allowScrollButtonsMobile={match}
                  value={tabIndex}
                  onChange={(e, newValue) => {
                    setTabIndex(newValue);
                    setSearchParams({ tabIndex: newValue });
                  }}>
                  <Tab label={match ? "Đơn ký" : "Đơn ký của tôi"} style={{ fontSize: "1rem", fontWeight: "bolder" }} />
                  <Tab label={match ? "Đồng ký" : "Đồng ký của tôi"} style={{ fontSize: "1rem", fontWeight: "bolder" }} />
                  <Tab label={match ? "Được mời ký" : "Được mời đồng ký"} style={{ fontSize: "1rem", fontWeight: "bolder" }} />
                </Tabs>
              </AppBar>
            </Box>
            <Box my={2}>
              <SwipeableViews
                axis={"x"}
                index={tabIndex}
                onChangeIndex={(index) => {
                  setTabIndex(index);
                  setSearchParams({ tabIndex: index });
                }}>
                <div hidden={tabIndex !== 0}>
                  <MySingleSignedDocs />
                </div>
                <div hidden={tabIndex !== 1}>
                  <MyMultiSignDocuments />
                </div>
                <div hidden={tabIndex !== 2}>
                  <MySignRequests {...{ signing, setSigning }} />
                </div>
              </SwipeableViews>
            </Box>
          </Paper>
        </Container>
      </Box>
    </>
  );
}
