import { AttachmentTypes } from "../../entities";
import DrawingDisplayerV2 from "./DrawingDisplayer_v2";
import ImageDisplayerV2 from "./ImageDisplayer_v2";
import TextDisplayerV2 from "./TextDisplayer_v2";

export default function AttachmentsDisplayer({ attachments }) {
  return (
    attachments &&
    attachments.map((attachment) => {
      if (attachment.type === AttachmentTypes.IMAGE) {
        return <ImageDisplayerV2 key={attachment.id} {...{ attachment }} />;
      }

      if (attachment.type === AttachmentTypes.DRAWING) {
        return <DrawingDisplayerV2 key={attachment.id} {...{ attachment }} />;
      }

      if (attachment.type === AttachmentTypes.TEXT) {
        return <TextDisplayerV2 key={attachment.id} {...{ attachment }} />;
      }

      return null;
    })
  );
}
