import React, { useEffect, useRef, useState } from "react";

interface Props {
  page: any;
  width: number;
  // dimensions?: Dimensions;
  // updateDimensions: ({ width, height }: Dimensions) => void;
}

export const Page = ({ page, width: w }: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [width, setWidth] = useState(0);
  // console.log("🚧 --> Page --> width", width);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const renderPage = async (p: Promise<any>) => {
      const _page = await p;
      if (_page) {
        const context = canvasRef.current?.getContext("2d");
        // const viewport = _page.getViewport({ scale: 600 / w });
        const viewport = _page.getViewport({ scale: 1 });

        setWidth(viewport.width);
        setHeight(viewport.height);

        if (context) {
          await _page.render({
            canvasContext: canvasRef.current?.getContext("2d"),
            viewport,
          }).promise;

          // const newDimensions = {
          //   width: viewport.width,
          //   height: viewport.height,
          // };

          // updateDimensions(newDimensions as Dimensions);
        }
      }
    };

    renderPage(page);
  }, [page]);

  return (
    <div style={{}}>
      <canvas ref={canvasRef} width={width} height={height} />
    </div>
  );
};
