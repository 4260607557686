import { Box, CircularProgress, Divider, IconButton, Paper, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { getSvgUri } from "src/components/AttachmentDisplayer/DrawingDisplayer_v2";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { fetchProfile } from "src/redux/slices/profile-slice";
import { useDispatch } from "react-redux";

export default function DisplaySignature({ title, imgSrc, svg, setEditing, type, isMajor }: any) {
  const [deleting, setDeleting] = useState<any>(false);
  const { enqueueSnackbar } = useSnackbar();
  const dp = useDispatch();

  async function deleteImage() {
    try {
      const response = await axios.patch("/signature/delete-signature?type=major");
      enqueueSnackbar("Đã xóa chữ ký!", { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
      dp(fetchProfile());
    } catch (error: any) {
      console.error(error);
      if (error.response) enqueueSnackbar(JSON.stringify(error.response.data), { variant: "error", anchorOrigin: { vertical: "top", horizontal: "center" } });
    }
  }

  return (
    <Paper sx={{ padding: 2 }}>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"flex-start"}>
        <Typography variant="h6">{title}</Typography>

        <Box>
          <IconButton onClick={() => deleteImage()} size="small" color="error" sx={{ mr: 1 }} disabled={deleting}>
            {deleting ? <CircularProgress sx={{ fontSize: "1rem" }} /> : <DeleteIcon />}
          </IconButton>

          <IconButton onClick={() => setEditing(true)} size="small">
            <EditIcon />
          </IconButton>
        </Box>
      </Box>

      <Divider />

      <Box sx={{ position: "relative", height: 150, marginTop: 2 }}>
        {type === "image" && imgSrc && <img src={imgSrc} alt="" style={{ maxWidth: "100%", height: "100%", objectFit: "cover" }} />}
        {type === "svg" &&
          svg &&
          (() => {
            const svgInfo = JSON.parse(svg);
            return (
              <div
                style={{
                  height: "100%",
                  backgroundImage: `url(${getSvgUri(svgInfo)})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              />
            );
          })()}
      </Box>
    </Paper>
  );
}
