type ScriptName = "pdfjsLib" | "PDFLib" | "w3Color";

interface Script {
  name: ScriptName;
  src: string;
}

const scripts: Script[] = [
  {
    name: "pdfjsLib",
    src: "https://unpkg.com/pdfjs-dist@2.3.200/build/pdf.min.js",
  },
  {
    name: "PDFLib",
    src: "https://cdnjs.cloudflare.com/ajax/libs/pdf-lib/1.17.1/pdf-lib.min.js",
  },
  { name: "w3Color", src: "https://www.w3schools.com/lib/w3color.js" },
];

const assets: Record<string, any> = {};
export const getAsset = (scriptName: string) => assets[scriptName];

export const prepareAssets = (): void => {
  // prepare scripts
  scripts.forEach(({ name, src }) => {
    assets[name] = new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(window[name as any]);
      };
      script.onerror = () => reject(`The script ${name} didn't load correctly.`);
      document.body.appendChild(script);
    });
  });
};
