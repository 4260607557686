export const ERR_TOP_CENTER = { variant: "error", anchorOrigin: { vertical: "top", horizontal: "center" } };

export const WARN_TOP_CENTER = { variant: "warning", anchorOrigin: { vertical: "top", horizontal: "center" } };

export const INFO_TOP_CENTER = { variant: "info", anchorOrigin: { vertical: "top", horizontal: "center" } };

export const SUCCESS_TOP_CENTER = { variant: "success", anchorOrigin: { vertical: "top", horizontal: "center" } };

export const SUCCESS_BOTTOM_CENTER = { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "center" } };

export const SUCCESS_BOTTOM_RIGHT = { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "right" } };
