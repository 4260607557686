import { TextField, Typography } from "@mui/material";
import { Button } from "semantic-ui-react";

export default function PageIndex({ pageIndexTFValue, setPageIndexTFValue, hdGoToPage, pages, hdPrevPage, hdNextPage, matches }) {
  const isMultiPage = pages.length > 1;
  const isFirstPage = pageIndexTFValue === 1;
  const isLastPage = pageIndexTFValue === pages.length;
  return (
    <div>
      {matches && isMultiPage && <Button circular icon="angle left" onClick={hdPrevPage} disabled={isFirstPage} style={{ marginRight: 12 }} />}
      <div style={{ display: "inline-flex", alignItems: "baseline" }}>
        <Typography display="inline">Trang</Typography>
        <TextField
          sx={{ marginX: "8px" }}
          variant="standard"
          size="small"
          margin="none"
          value={Number(pageIndexTFValue)}
          onKeyPress={(e) => {
            if (e.key === "Enter") hdGoToPage();
          }}
          onChange={(e) => setPageIndexTFValue(Number(e.target.value))}
          inputProps={{ style: { width: 30, textAlign: "center" } }}></TextField>
        <Typography display="inline">/ {pages.length}</Typography>
      </div>
      {matches && isMultiPage && <Button circular icon="angle right" onClick={hdNextPage} disabled={isLastPage} style={{ marginLeft: 12 }} />}
    </div>
  );
}
