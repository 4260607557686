import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, Container, Fade, IconButton, Link, Menu, MenuItem, Typography } from "@mui/material";
import QRCode from "qrcode";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { fetchProfile, resetProfile } from "src/redux/slices/profile-slice";
import { clearToken, getToken } from "src/utility/mng-token";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import logo from "./hust-logo.jpeg";
import QRCodeDialog from "./QRCodeDialog";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Header() {
  const [menuAnchorEl, setMenuAnchorEl] = useState<any>(null);
  const navigate = useNavigate();
  const profile = useAppSelector((state) => state.profile);
  const dp = useAppDispatch();
  const [qrCodeModel, setQRCodeModal] = useState<any>();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  async function hdShowQRCode() {
    const dataURL = await QRCode.toDataURL(profile.TOTPURL);
    setQRCodeModal(<QRCodeDialog secret={profile.TOTPSecret} qrURL={dataURL} close={() => setQRCodeModal(null)} />);
  }

  function hdLogout() {
    clearToken();
    setMenuAnchorEl(null);
    navigate("/sign-in");
    dp(resetProfile({}));
  }

  useEffect(() => {
    dp(fetchProfile());
  }, []);

  return (
    <div style={{ backgroundColor: "#9b0504", color: "white", padding: "8px 0" }}>
      <Container fixed>
        {matches && (
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Box sx={{ height: 100, display: "flex", alignItems: "center" }}>
              <Link component={RouterLink} to="/" sx={{ height: "100%" }}>
                <img src={logo} style={{ height: "100%", width: "auto", maxWidth: "100%", marginRight: 16 }} alt="" />
              </Link>
              <div>
                <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>TRƯỜNG ĐẠI HỌC BÁCH KHOA HÀ NỘI</Typography>
                <Typography>HỆ THỐNG QUẢN TRỊ ĐẠI HỌC TRỰC TUYẾN</Typography>
                {/* <Typography> TRƯỜNG CÔNG NGHỆ THÔNG TIN VÀ TRUYỀN THÔNG</Typography> */}
              </div>
            </Box>

            {getToken() && (
              <Box textAlign={"center"}>
                <IconButton size="large" onClick={(event) => setMenuAnchorEl(event.currentTarget)}>
                  <AccountCircleIcon sx={{ color: "white" }} fontSize="large" />
                </IconButton>

                <Menu anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={() => setMenuAnchorEl(null)} TransitionComponent={Fade}>
                  <MenuItem>Đổi mật khẩu</MenuItem>
                  <MenuItem onClick={hdShowQRCode}>QR Code</MenuItem>
                  <MenuItem onClick={() => hdLogout()}>Đăng xuất</MenuItem>
                </Menu>
                <Typography>{profile.email}</Typography>
              </Box>
            )}
          </Box>
        )}

        {!matches && (
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "center" }}>
              <Link component={RouterLink} to="/" sx={{ height: "100%" }}>
                <img src={logo} style={{ maxHeight: "100px", width: "auto", maxWidth: "100%", marginRight: 16 }} alt="" />
              </Link>
              <div style={{ textAlign: "center" }}>
                <Typography sx={{ fontSize: "0.85rem", fontWeight: "bold" }} gutterBottom>
                  TRƯỜNG ĐẠI HỌC BÁCH KHOA HÀ NỘI
                </Typography>
                <Typography sx={{ fontSize: "0.75rem" }} gutterBottom>
                  HỆ THỐNG QUẢN TRỊ ĐẠI HỌC TRỰC TUYẾN
                </Typography>
                {/* <Typography sx={{fontSize: "0.85rem"}} gutterBottom> TRƯỜNG CÔNG NGHỆ THÔNG TIN VÀ TRUYỀN THÔNG</Typography> */}
              </div>
            </Box>

            {getToken() && (
              <Box textAlign={"center"}>
                <IconButton size="large" onClick={(event) => setMenuAnchorEl(event.currentTarget)}>
                  <AccountCircleIcon sx={{ color: "white" }} fontSize="large" />
                </IconButton>

                <Menu anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={() => setMenuAnchorEl(null)} TransitionComponent={Fade}>
                  <MenuItem>Đổi mật khẩu</MenuItem>
                  <MenuItem onClick={hdShowQRCode}>QR Code</MenuItem>
                  <MenuItem onClick={() => hdLogout()}>Đăng xuất</MenuItem>
                </Menu>
                <Typography>{profile.email}</Typography>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </div>
  );
}
