import axios from "axios";
import { hustAxios } from "src/config/axios-config";
import { handlers } from "src/hooks/useUploader";
import { updateImgSrc, updateSvg } from "src/redux/slices/profile-slice";
import { addAttachment, addPlaceholder } from "../../redux/slices/attachment";
import { convertDrawingToAttachment, convertImageToAttachment, genSignaturePlaceholder, genTextAttachment } from "./utils";

export async function hdUploadMajorSignature(e: React.ChangeEvent<HTMLInputElement>, dp, eq, setEditingMajor) {
  if (e.target.files) {
    const formData = new FormData();
    formData.append("signature", e.target.files[0]);
    try {
      const response = await axios.post("/signature/save-image?type=major", formData);
      dp(updateImgSrc({ majorSignatureImgSrc: response.data, majorType: "image" }));
      setEditingMajor(false);
      // eq("Upload chữ ký chính thành công", { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
      eq("Upload chữ ký thành công", { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    } catch (error: any) {
      error.response && eq(JSON.stringify(error.response.data), { variant: "error" });
    }
  }
}

export async function hdUploadMinorSignature(e: React.ChangeEvent<HTMLInputElement>, dp, eq, setEditingMinor) {
  if (e.target.files) {
    const formData = new FormData();
    formData.append("signature", e.target.files[0]);
    try {
      const response = await axios.post("/signature/save-image?type=minor", formData);
      dp(updateImgSrc({ minorSignatureImgSrc: response.data, minorType: "image" }));
      setEditingMinor(false);
      eq("Upload chữ ký nháy thành công", { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    } catch (error: any) {
      error.response && eq(JSON.stringify(error.response.data), { variant: "error" });
    }
  }
}

export async function hdSaveSvgMajor(drawing: any, dp, eq, setEditingMajor) {
  try {
    const response = await axios.post("/signature/save-svg?type=major", { drawing: JSON.stringify(drawing) });
    // eq("Cập nhật chữ ký chính thành công!", { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    eq("Cập nhật chữ ký thành công!", { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    setEditingMajor(false);
    dp(updateSvg({ majorSignatureSvg: JSON.stringify(drawing), majorType: "svg" }));
  } catch (error: any) {
    console.log("Err when hdSaveSvgMajor");
    error.response && eq(JSON.stringify(error.response.data), { variant: "error" });
  }
}

export async function hdSaveSvgMinor(drawing: any, dp, eq, setEditingMinor) {
  try {
    const response = await axios.post("/signature/save-svg?type=minor", { drawing: JSON.stringify(drawing) });
    eq("Cập nhật chữ ký nháy thành công!", { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    setEditingMinor(false);
    dp(updateSvg({ minorSignatureSvg: JSON.stringify(drawing), minorType: "svg" }));
  } catch (error: any) {
    console.log("Err when hdSaveSvgMajor");
    error.response && eq(JSON.stringify(error.response.data), { variant: "error" });
  }
}

export async function hdInsertMajorSignature(profile, pageIndex, dp) {
  if (profile.majorType === "image") {
    if (!profile.majorSignatureImgSrc) return;
    const newImageAtt = await convertImageToAttachment(profile.majorSignatureImgSrc, pageIndex);
    dp(addAttachment(newImageAtt));
  } else if (profile.majorType === "svg") {
    if (!profile.majorSignatureSvg) return;
    const newDrawingAtt = convertDrawingToAttachment(JSON.parse(profile.majorSignatureSvg), pageIndex);
    dp(addAttachment(newDrawingAtt));
  }
}

export async function hdInsertMinorSignature(profile, pageIndex, dp) {
  if (profile.minorType === "image") {
    if (!profile.minorSignatureImgSrc) return;
    const newImageAtt = await convertImageToAttachment(profile.minorSignatureImgSrc, pageIndex);
    dp(addAttachment(newImageAtt));
  } else if (profile.minorType === "svg") {
    if (!profile.minorSignatureSvg) return;
    const newDrawingAtt = convertDrawingToAttachment(JSON.parse(profile.minorSignatureSvg), pageIndex);
    dp(addAttachment(newDrawingAtt));
  }
}

export function hdInsertTextInput(pageIndex, dp) {
  dp(addAttachment(genTextAttachment(pageIndex)));
}

export function hdInsertSignPlaceholder(email, pageIndex, dp) {
  dp(addPlaceholder(genSignaturePlaceholder(email, pageIndex)));
}

export async function fetchPdfFromURL(pdfURL, setFetching, initialize, enqueueSnackbar) {
  if (pdfURL) {
    const parts = pdfURL.split("/");
    const pdfName = parts[parts.length - 1];
    try {
      setFetching(true);
      const noCachedURL = pdfURL + "?t=" + Date.now();
      const response = await hustAxios.get(noCachedURL, { responseType: "blob" });
      // const response = await axios.get(`/dev/get-pdf-file?url=${pdfURL}`, { responseType: "blob" });
      const file = new File([response.data], pdfName, { type: "application/pdf" });
      const result = await handlers["pdf"](file);
      initialize(result);
    } catch (error) {
      enqueueSnackbar("Fail to fetch PDF file!", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "center" } });
    } finally {
      setFetching(false);
    }
  }
}